import React from "react";
import banner from "../../../images/carousel/slide_1/cherry_blossom_2024.jpg";
import lovelySakura from "../../../images/carousel/slide_1/sakura/lovely_sakura.jpg";

function Sakura() {

    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Kirsebærblomstring i Japan:</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">En Hyldest til Skønhed og Forfald</h2>
                    <p className="font-bold text-xl text-white max-w-[900px] drop-shadow-[2px_4px_6px_black] max-[425px]:text-lg max-[425px]:mt-4">Opdag den flygtige skønhed og dybe symbolik bag Japans ikoniske kirsebærblomster gennem årstidens foranderlige prægtighed og betagende forfald.</p>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 gap-4 flex flex-col rounded-lg">
                    <div className="flex gap-4 max-[1240px]:flex-wrap">
                        <div className="flex flex-col gap-4 p-5 justify-start text-white rounded-lg drop-shadow-[2px_4px_6px_black]">
                            <p>Velkommen til en fortryllende rejse gennem de blomstrende lunde i Japan, hvor kirsebærblomsterne, også kendt som "sakura", kaster en fortryllende aura over landets landskab og kultur.</p>
                            <p>Hvert forår vækkes Japan til live af et overvældende hav af sarte rosa og hvide blomster, der delikat pryder træerne i et betagende skue af naturpragt. Kirsebærblomstringen symboliserer ikke blot begyndelsen på en ny sæson, men også et dybtgående tema om skønhedens forgængelighed og det flygtige øjeblik.</p>
                            <p>At opleve kirsebærblomstringen i Japan er en enestående mulighed for at fordybe sig i landets rige kultur og traditioner. Fra de ikoniske kirsebæralléer i Tokyo til de maleriske haver i Kyoto er der utallige steder at udforske og nyde denne ærefulde begivenhed.</p>
                        </div>
                        <img className="max-[1240px]:max-w-full max-w-[800px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg" src={lovelySakura} alt="Et par der står under kirsebærtræer i blomst om natten" />
                    </div>
                    <div className="flex flex-col gap-4 p-5 text-white drop-shadow-[2px_4px_6px_black] rounded-lg">
                        <p>Udforsk de historiske templer og slotte, hvor kirsebærtræerne står som stille vidner om fortidens arv. Deltag i det lokale fællesskab under åben himmel i parkerne, hvor cherry blossom-sæsonen fejres med hjertevarme picnics og traditionelle ceremonier.</p>
                        <p>Forbered dig på at lade dig fortrylle af den flygtige skønhed og poetiske forfald, der følger med kirsebærblomstringen i Japan. Lad dig inspirere af øjeblikkets dyrebarehed og oplev en tidløs rejse gennem naturens skiftende skønhed.</p>
                        <p>Lad os udforske nogle af de mest bemærkelsesværdige steder at opleve sakura i Japan:</p>
                        <ul className="text-lg pl-2">
                            <li className="float-right">
                                <figure className="flex flex-col">
                                    <img className="rounded-lg mt-4 mx-2" src="https://media.odynovotours.com/article/69000/japan-cherry-blossom-map-2024_68075.jpg" alt="Sakura Forcast Map" />
                                    <figcaption className="px-4 italic -mt-6 text-black max-w-[800px] pl-2 self-center w-full font-bold rounded-b-lg">Kort over Cherry Blossom 2024</figcaption>
                                </figure>
                            </li>
                            <li>
                                <h3 className="font-bold">Tokyo:</h3>
                                <p className="pl-4">Tokyo er hjemsted for nogle af de mest ikoniske kirsebæralléer, herunder det berømte Chidorigafuchi og Meguro-floden. Disse steder er kendt for deres storslåede panoramaer, der tiltrækker tusinder af besøgende og fotografer hvert år.</p>
                            </li>
                            <li className="mt-4">
                                <h3 className="font-bold">Kyoto:</h3>
                                <p className="pl-4">Kyoto's charme under kirsebærblomstringen er uovertruffen. Besøg steder som Maruyama Park, hvor det store Yoshino-templet står stolt blandt blomstrende træer, eller filosofernes sti, hvor kirsebærtræerne danner en tunnel af skønhed og eftertanke.</p>
                            </li>
                            <li className="mt-4">
                                <h3 className="font-bold">Osaka:</h3>
                                <p className="pl-4">I Osaka kan du opleve kirsebærblomsterne i det fantastiske Osaka Castle Park, hvor det majestætiske slot danner en enestående baggrund for blomstrende træer og fredfyldte vandrender.</p>
                            </li>
                            <li className="mt-4">
                                <h3 className="font-bold">Hokkaido:</h3>
                                <p className="pl-4">Hokkaido, Japans nordligste ø, byder på en mere forsinket kirsebærblomstring på grund af det køligere klima. Blandt de mest spektakulære steder er Goryokaku-fortet i Hakodate, hvor kirsebærtræerne indrammer det stjerneformede fort i en fortryllende scene.</p>
                            </li>
                        </ul>
                        <p>Disse steder tilbyder kun en smagsprøve på den overvældende skønhed og kulturelle betydning af sakura i Japan. Uanset hvor du vælger at udforske, vil du blive grebet af den uimodståelige magi, som cherry blossom-sæsonen bringer til landet.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Sakura;