import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/attractions/attractions_hq.webp";
import content from "../../../images/japan_by_interests/attractions/content.webp";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japans Attraktioner';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japans Attraktioner</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Besøg århundreder gamle helligdomme, udforsk uberørte nationalparker, shop i smarte bycentre eller slap bare af på et ø-resort ved stranden.</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Start med de klassiske templer i Kyoto eller underholdningscentrene i Tokyo, men udforsk lidt længere for at gøre dine egne friske og spændende opdagelser</h3>
                    <p>På tværs af Japan vil du finde kulturelt arvegods, der er højt vurderet efter internationale standarder. Oplev brancheførende arkitektur og design og nyd skønheden i de skiftende årstider, fra storslåede haver til stormagasiner og køkken. Forlad byerne bag dig og opdag varme kilder landsbyer i bjergene, hvide sandstrande og en række eventyraktiviteter.</p>
                    <h3 className="font-bold mt-4">Kulturelle Skatte i Japan</h3>
                    <p>Oplev Japans rige kulturelle arv med vores guide til landets mest ikoniske attraktioner. Udforsk gamle templer og helligdomme, dyk ned i traditionel japansk kunst og arkitektur, og smag på autentiske japanske retter. Fra Kyoto's fortryllende Arashiyama-bambusskov til Tokyo's pulserende Asakusa-kvarter har Japan en skattekiste af oplevelser for den kulturelt nysgerrige rejsende.</p>
                    <h3 className="font-bold mt-4">Naturens Vidundere i Japan</h3>
                    <p>Gå på opdagelse i Japans betagende natur med vores guide til landets mest imponerende naturlige attraktioner. Vandreture gennem frodige nationalparker, opdag skjulte vandfald i afsides bjergområder, og slap af på paradisiske strande langs kysten. Fra de sneklædte tinder i Hokkaido til de azurblå farvande omkring Okinawa byder Japan på en mangfoldighed af naturoplevelser.</p>
                    <h3 className="font-bold mt-4">Eventyr og Adrenalin i Japan</h3>
                    <p>Find din indre eventyrer med vores guide til actionfyldte oplevelser i Japan. Tag på snowboarding i verdensklasse i de japanske alper, dyk ned i krystalklare dybhavsdykningsspots, og udforsk de skjulte perler af eventyraktiviteter i Japans landlige områder. Fra at vandre langs den ikoniske Nakasendo-sti til at udforske skjulte vandfald i Yakushima-øens regnskov er der ingen mangel på spænding i Japan.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;