import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/nature/nature_hq.webp";
import content from "../../../images/japan_by_interests/nature/content.jpg";

function Shopping() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japans Natur';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japans Natur</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Et land bestående af bjerge, skove og strækninger af kystlinje, Japan giver dig masser af muligheder for at tilbringe tid blandt naturen</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Udforsk Tokyos grønnere side med en nem dagstur, tag til de kystnære områder for fantastiske strande og takketandede kystlinjer, eller gå dybt ind i bjergene for seriøs vandring</h3>
                    <p>En vandretur til toppen af ​​Mount Takao kan gøres inden for en dag fra hovedstaden, mens du kan tilbringe flere dage med at gå pilgrimsruterne, der snor sig gennem Kii-bjergkæden i det vestlige Japan.</p>
                    <p className="mt-2">Ikke kun begrænset til bjergudsigt, er landets kystlinjer ofte punkteret med tårnhøje monumenter af klipper, der er skabt af års erosion, og strande strækker sig langs klart blåt vand.</p>
                    <p className="mt-2">Afhængigt af hvilken sæson du vælger at besøge, vil den naturlige baggrund ændre sig fra de sarte lyserøde blomster i kirsebærblomstsæsonen om foråret, de friske grønne farver om sommeren, de gyldne nuancer om efteråret og bjergene dækket af puddersne om vinteren. Tilbring tid blandt de sæsonbetonede løv i haver og nationalparker. Læs mere om Japans rekreative skove og de mange aktiviteter, du kan nyde i dem.</p>
                    <h3 className="font-bold mt-4">Opdag Japans naturlige vidundere</h3>
                    <p>Fra majestætiske bjerge til fortryllende kystlinjer byder Japan på en overflod af naturlige vidundere, der venter på at blive udforsket. Tag på en rejse gennem landets uberørte nationalparker, hvor du kan vandre gennem skove fyldt med kirsebærblomster om foråret og farverige løv om efteråret. Oplev den storslåede skønhed ved kysten, hvor høje klippeformationer møder det klare, azurblå hav. Uanset om du er til trekking i bjergene, afslapning på en malerisk strand eller blot at nyde den naturlige skønhed, vil Japans natur efterlade dig fortryllet.</p>
                    <h3 className="font-bold mt-4">Japans Naturskønhed: Fra Bjerge til Strande</h3>
                    <p>Træd ind i et paradis af kontraster, hvor Japans rige natur strækker sig fra majestætiske bjergtoppe til bølgende bølger ved kysten. Begiv dig ud på eventyr langs de velplejede stier i landets nationalparker, hvor du kan opdage skjulte vandfald, møde vilde aber og nyde panoramaudsigten fra bjergtinderne. Tag til kysten og fordyb dig i den rolige atmosfære på de billedskønne strande, hvor du kan snorkle blandt farverige koralrev eller bare nyde solnedgangen over det stille hav.</p>
                    <h3 className="font-bold mt-4">Årstidernes Skønhed: Oplev Japans Naturårstider</h3>
                    <p>Lad dig fortrylle af naturens skiftende ansigter, når du udforsker Japans naturlige vidundere gennem årets fire årstider. Om foråret vil du blive mødt af det lyserøde tæppe af kirsebærblomster, der dækker landet i en skønhedsåbenbaring. Sommeren bringer varmen og muligheden for at svømme i krystalklare søer og floder eller vandre gennem skyggefulde skove. Efteråret maler landskabet i gyldne og røde nuancer, mens vinteren indhyller bjergene i et tæppe af sne. Uanset hvilken årstid du vælger at besøge, vil Japans natur forbløffe dig med sin skønhed og variation.</p> 
               </div>
            </div>
        </section>
    );
};

export default Shopping;