import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/february/february_hq.webp";
import content from "../../../images/japan_by_month/february/content.webp";

function GuideFeb() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i Februar';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i Februar</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Højt humør i slutningen af vinteren</h3>
                    <p>Med noget af årets koldest vejr kræver Februar fysisk aktivitet og flere lag for at holde varmen. Sæsonbestemte begivenheder fejrer ofte en af to ting, vintervejr, bedst set i Sapporos vildt populære snefestival, eller grænseløs kraft, som med Saidai-ji Temple Eyo (Naked Man Festival). Vintersport er en populær måde at passere en snevejrshelg på, og pisteforholdene forbliver usædvanligt gode det meste af måneden i Japans bedste snesportområder.</p>
                    <h3 className="font-bold mt-4">En ændring af sæsonen?</h3>
                    <p>En overraskende mængde af Japans nationale identitet hviler på ideen om fire forskellige årstider. Forår, sommer, efterår og vinter opfører sig alle og holder sig til manuskriptet, så historien går. Og alligevel har den traditionelle japanske kalender, mærkeligt nok, foråret begynder den 4. februar. Lad dig dog ikke narre-Japan er stadig meget køligt (og også ret snedækket) nogle steder, og du vil gerne klæde dig passende.</p>
                    <h3 className="font-bold mt-4">Vinterbegivenheder for at varme sjælen</h3>
                    <p>Den ubestridte tophund af vinter-tema begivenheder i Japan, Sapporo sne Festival trækker over 2 millioner besøgende hvert år. Et stort udvalg af is-og sneskulpturer på flere steder giver hovedattraktionen, men det slutter ikke der. Mad og drikke, der tilbydes i utallige kiosker, holder stemningen flydende, når temperaturen falder. Lignende festivaler med færre folkemængder er Akita Yokote Kamakura Festival , Niigatas Tokamachi Snefestival og Aomoris Hirosaki Castle sne Lantern Festival.</p>
                    <p>For noget helt andet, tjek Saidai-ji Temple You (Naked Man Festival) i Okayama . Med snesevis af loincloth-klædte mænd, der kæmper for kære liv for at få deres hænder på shingi (hellig træpind), der vil bringe dem held, er det et godt eksempel på, hvordan nogle ting bare skal ses for at blive troet. Forvent pummeling, åndeløst drama, lynhurtige tilbageførsler af formue, og bare kød overalt, hvor du ser ud. Det er underholdning!</p>
                    <h3 className="font-bold mt-4">Begyndelsen af foråret</h3>
                    <p>På trods af kulden er der et tema af fremvoksende vitalitet og nye brgyndelser i Februar. Mod den forholdsvis varmere sidste halvdel af måneden blomstrer blommetræer der varsler forårets begyndelse. Kairakuen Garden i Ibaraki, koishikawa Korakuen i Tokyo, Kitano Tenmangu Shrine i Kyoto og Dazaifu Tenmangu Shrine i Fukuoka er de bedste steder at se blomstringen.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideFeb;