import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../logo.png';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [shouldHideMenu, setShouldHideMenu] = useState(true);

    // Toggle mobile menu open/close
    const toggleMenu = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
            setTimeout(() => {
                setShouldHideMenu(true);
            }, 1100);
        } else {
            setShouldHideMenu(false);
            setIsMenuOpen(true);
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            setShouldHideMenu(false);
        }
    }, [isMenuOpen]);

    return (
    <header className="h-16 bg-[linear-gradient(180deg,_black_-100%,_transparent_100%)]">
        <nav className="h-full w-full flex max-[560px]:hidden justify-center items-center px-4">
            <div className="navbar-start">
                <Link to="/" className="flex title-font text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#d7d7d7] from-30% to-[#ff879e] to-90% tracking-widest" title="Logo - Travel">
                    <img className="w-16 p-1" src={logo} alt="Logo"/>
                    <span className="max-[1280px]:hidden">RYOKO</span>
                </Link>
            </div>
            <ul className="navbar-center">
                <li className="text-slate-200 text-xl font-semibold rounded-lg transition-all duration-500 bg-img-effect home">
                    <Link to="/" className="py-3 px-4 flex">Startside</Link>
                </li>
                <li className="text-slate-200 text-xl font-semibold rounded-lg transition-all duration-500 bg-img-effect destinations">
                    <Link to="/destinationer" className="nav-link py-3 px-4 flex">Destinationer</Link>
                </li>
                <li className="text-slate-200 text-xl font-semibold rounded-lg transition-all duration-500 bg-img-effect things-to-do">
                    <Link to="/oplevelser" className="nav-link py-3 px-4 flex">Oplevelser</Link>
                </li>
            </ul>
            <div className="navbar-end"/>
        </nav>
        <nav className="hidden max-[560px]:flex h-full w-full justify-between px-4">
            <div className="navbar-start">
                <Link to="/" className="flex title-font text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#d7d7d7] from-30% to-[#ff879e] to-90% tracking-widest" title="Logo - Travel">
                    <img className="w-16 p-1" src={logo} alt="Logo"/>
                    <span className="max-[1280px]:hidden">RYOKO</span>
                </Link>
            </div>
            <button id="menu-btn" className="focus:outline-none h-full w-12 justify-center items-center flex flex-col z-[1]" onClick={toggleMenu}>
                <span className={`z-0 bg-slate-300 h-1 w-4/5 rounded-lg bar bar1 ${isMenuOpen ? "opened" : ""}`}></span>
                <span className={`z-0 bg-slate-300 mt-3 h-1 w-4/5 rounded-lg bar bar2 ${isMenuOpen ? "opened" : ""}`}></span>
            </button>
        </nav>
        <div className={`mobile-menu hidden max-[560px]:flex absolute right-0 w-full bg-slate-500 z-10 h-fit ${isMenuOpen ? "fly-in-from-right" : "fly-out-to-right"}`}>
            <nav className="w-full drop-shadow-[2px_4px_6px_black] text-white text-s">
                <ul className={`flex-col items-center justify-center ${!isMenuOpen && shouldHideMenu ? "hidden" : "flex"}`}>
                    <li className={`w-full relative before:bg-[url(../images/btn_bgs/landing.jpg)] mobile-menu-bgs`}>
                        <Link to="/" className="w-full flex justify-center py-2 text-xl font-semibold">Startside</Link>
                    </li>
                    <li className={`w-full relative before:bg-[url(../images/btn_bgs/destination.webp)] mobile-menu-bgs`}>
                        <Link to="/destinationer" className="w-full flex justify-center py-2 text-xl font-semibold">Destinationer</Link>
                    </li>
                    <li className={`w-full relative before:bg-[url(../images/btn_bgs/exp.jpg)] mobile-menu-bgs`}>
                        <Link to="/oplevelser" className="w-full flex justify-center py-2 text-xl font-semibold">Oplevelser</Link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    );
}

export default Header;