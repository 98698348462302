import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/adventure/adventure_hq.webp";
import content from "../../../images/japan_by_interests/adventure/content.webp";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Eventyr i Japan';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Eventyr i Japan</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Rejs gennem traditionerne og de naturskønne landskaber i Japan</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Templer, Traditioner og Te: En Rejsendes Guide til Det Gamle Japan</h3>
                    <p>Dyk ned i Japans rige kulturarv ved at udforske dets antikke templer, deltage i traditionelle ceremonier og nyd en forfriskende kop japansk te. Oplev Kyoto's fortryllende atmosfære, Nara's majestætiske pagoder og Kamakura's historiske skatte.</p>
                    <h3 className="font-bold mt-4">Naturparadis: Opdagelse af Japans Skjulte Naturskønhed</h3>
                    <p>Tag på en uforglemmelig rejse gennem Japans uberørte nationalparker, fra de snoede stier i Yakushima's tætte skove til de smaragdgrønne søer i Hokkaido's Shikotsu-Toya Nationalpark. Udforsk det storslåede landskab og kom tættere på naturen.</p>
                    <h3 className="font-bold mt-4">Urban Chic: En Byeventyr i Japans Pulserende Metropoler</h3>
                    <p>Forbered dig på en eksplosion af liv og farver i Japans moderne bycentre. Fra de travle gader i Tokyo's Shibuya-kryds til Osaka's kulinariske mekka Dotonbori, oplev en blanding af innovation, tradition og kultur i landets dynamiske byer.</p>
                    <h3 className="font-bold mt-4">Øhop og Strandsjov: En Afslappende Retreat til Japans Ø-Paradis</h3>
                    <p>Find fred og ro på Japans smukke øparadiser, hvor azurblåt vand møder hvide sandstrande. Udforsk Okinawas korallrev, slentre langs Miyako-jima's kyst eller nyd en solnedgang på Ishigaki's tropiske kystlinje. Oplev den ultimative ø-ferieoplevelse i Japan.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;