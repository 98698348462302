import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import img1 from "../images/carousel/slide_1/cherry_blossom_2024.jpg";
import img2 from "../images/carousel/slide_2/things_to_do_in_japan.jpg";
import img3 from "../images/carousel/slide_3/unikke_oplevelser.jpg";
import img4 from "../images/carousel/slide_4/japanese_culture.jpg";
import month1 from "../images/japan_by_month/january.jpg";
import month2 from "../images/japan_by_month/february.jpg";
import month3 from "../images/japan_by_month/march.jpg";
import month4 from "../images/japan_by_month/april.jpg";
import month5 from "../images/japan_by_month/may.jpg";
import month6 from "../images/japan_by_month/june.jpg";
import month7 from "../images/japan_by_month/july.jpg";
import month8 from "../images/japan_by_month/august.jpg";
import month9 from "../images/japan_by_month/september.jpg";
import month10 from "../images/japan_by_month/october.jpg";
import month11 from "../images/japan_by_month/november.jpg";
import month12 from "../images/japan_by_month/december.jpg";
import interest1 from "../images/japan_by_interests/attractions.webp";
import interest2 from "../images/japan_by_interests/culture.jpg";
import interest3 from "../images/japan_by_interests/relax.jpg";
import interest4 from "../images/japan_by_interests/shopping.jpg";
import interest5 from "../images/japan_by_interests/art.jpg";
import interest6 from "../images/japan_by_interests/history.jpg";
import interest7 from "../images/japan_by_interests/nature.jpg";
import interest8 from "../images/japan_by_interests/events.jpg";
import interest9 from "../images/japan_by_interests/food.jpg";
import interest10 from "../images/japan_by_interests/parks.jpg";
import interest11 from "../images/japan_by_interests/heritage.jpg";
import interest12 from "../images/japan_by_interests/adventure.jpg";

function Home() {
    // eslint-disable-next-line
    const [carouselItems, setCarouselItems] = useState([
        { 
            // Cherry Blossom
            image: img1, 
            title: "Kirsebærblomstring i Japan: En Hyldest til Skønhed og Forfald", 
            text: "Forår i Japan betyder ikke kun genfødsel, men også en ærefuld dans mellem skønhed og forgængelighed. Oplev historien, symbolikken og den dybe forbindelse med naturen bag Japans ikoniske kirsebærblomster, der fortryller sind og hjerter med deres korte, men uendeligt smukke blomstring.",
            cta: "Udforsk kirsebærblomstringen nu!",
            link: "/sakura"
        },
        { 
            // Adventures
            image: img2, 
            title: "Oplev Japan: Et Uforglemmeligt Eventyr", 
            text: "Fra de blomstrende sakura-træer i Kyoto til det pulserende liv i Tokyo tilbyder Japan en verden af kontraster og skønhed. Udforsk det gamle Kioto's mystik, føl den moderne rytme i Tokyo, og lad din smagsløg opleve den delikate japanske cuisine. Dyk ned i naturens skønhed fra japanske Alper til Okinawa's strande. Klar til at begynde din rejse til det uforglemmelige Japan?",
            cta: "Start din rejse nu!",
            link: "/adventures"
        },
        {
            // Unikke Oplevelser
            image: img3,
            title: "En Verden af Unikke Oplevelser i Japan",
            text: "Træd ind i en verden af unikke oplevelser i Japan, hvor tradition møder modernitet og skaber uforglemmelige øjeblikke. Deltag i en traditinel te-ceremoni, opleve spændingen ved en sumo-kamp eller fordyb dig i skønheden af Arshiyamas bambusskov. Lad Japan inspirer og overraske dig med sin mangfoldighed og charme.",
            cta: "Udforsk Unikke Oplevelser i Japan",
            link: "/uniqueexp"
        },
        {
            // Kulturel Rigdom
            image: img4,
            title: "Dyk ned i Japans Kulturelle Rigdomme",
            text: "Oplev en dybdegående kulturel rigdom i Japan, hvor hvert øjeblik er en lektion i historie, kunst og tradition. Fra arkitekturen i gamle templer og helligdomme til de levende farver og bevægelser i traditionelle festivaler, er Japan en skattekiste af kulturelle oplevelser. Lad dig fortrylle af den ærefulde æstetik og dybe betydning, der er indlejret i hver kulturperle i dette fascinerende land.",
            cta: "Opdag Japans Kulturelle Skatte",
            link: "/culturalexp"
        }
    ]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [timer, setTimer] = useState(0);

    // Highlights carousel
    const highlights = useMemo(() => [
        { month: 'Januar', imageUrl: month1, link: "/guidejan"},
        { month: 'Februar', imageUrl: month2, link: "/guidefeb"},
        { month: 'Marts', imageUrl: month3, link: "/guidemarts"},
        { month: 'April', imageUrl: month4, link: "/guideapril"},
        { month: 'Maj', imageUrl: month5, link: "/guidemaj"},
        { month: 'Juni', imageUrl: month6, link: "/guidejuni"},
        { month: 'Juli', imageUrl: month7, link: "/guidejuli"},
        { month: 'August', imageUrl: month8, link: "/guideaug"},
        { month: 'September', imageUrl: month9, link: "/guidesep"},
        { month: 'Oktober', imageUrl: month10, link: "/guideokt"},
        { month: 'November', imageUrl: month11, link: "/guidenov"},
        { month: 'December', imageUrl: month12, link: "/guidedec"},
    ], []);
    const [startIndex, setStartIndex] = useState(0);
    const [extendedHighlights, setExtendedHighlights] = useState([...highlights, ...highlights]);

    const handlePrev = () => {
        setStartIndex((prevIndex) => (prevIndex === 0 ? extendedHighlights.length / 2 - 1 : prevIndex - 1));
    };
    
    const handleNext = () => {
        setStartIndex((prevIndex) => (prevIndex === extendedHighlights.length / 2 - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        // Extend highlights dynamically when approaching the end of the current highlights array
        if (startIndex > extendedHighlights.length - 8) {
            setExtendedHighlights([...extendedHighlights, ...highlights]);
        }
    }, [startIndex, extendedHighlights, highlights]);

    // Explorer by interest
    // eslint-disable-next-line
    const [interests, setInterests] = useState(() => [
        { text: 'Japans Attraktioner', imageUrl: interest1, link: "/attraktioner"},
        { text: 'Japans Kultur', imageUrl: interest2, link: "/kultur"},
        { text: 'Afslappelse i Japan', imageUrl: interest3, link: "/afslappelse"},
        { text: 'Shopping i Japan', imageUrl: interest4, link: "/shopping"},
        { text: 'Japans Kunst og Design', imageUrl: interest5, link: "/kunst"},
        { text: 'Japans Historie', imageUrl: interest6, link: "/historie"},
        { text: 'Japans Natur', imageUrl: interest7, link: "/natur"},
        { text: 'Festivaller og Events i Japan', imageUrl: interest8, link: "/events"},
        { text: 'Mad og Drikke i Japan', imageUrl: interest9, link: "/madogdrikke"},
        { text: 'Nationale Parker', imageUrl: interest10, link: "/parker"},
        { text: 'Japans Kulturarv', imageUrl: interest11, link: "/kulturarv"},
        { text: 'Eventyr i Japan', imageUrl: interest12, link: "/eventyr"},
    ])

    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Rejseguider til Japan';
    }, []);

    // First carousel effect
    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = (currentImageIndex + 1) % carouselItems.length;
            setCurrentImageIndex(nextIndex);
            setTimer(0);
        }, 7000);

        return () => clearInterval(interval);
    }, [currentImageIndex, carouselItems]);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 100);
        }, 100);

        return () => clearInterval(timerInterval);
    }, []);

    const handleDotClick = (index) => {
        setCurrentImageIndex(index);
        setTimer(0);
    };

    // Calculate percentage filled based on timer
    const fillPercentage = (timer / 7000) * 100;

    return (
        <>
            <section>
                <div className="carousel-container h-full relative overflow-hidden">
                    <div className="flex transition-transform duration-500 ease-in-out w-[calc(100vw_*_4)] max-h-[550px] h-full min-[220px]:max-[899px]:max-h-none" style={{ transform: `translateX(-${(currentImageIndex * 100) / carouselItems.length}%)` }}>
                        {carouselItems.map((item, index) => (
                            <div key={index} className="carousel-item w-full flex flex-row-reverse justify-end py-12 px-20 min-[220px]:max-[899px]:relative min-[220px]:max-[899px]:my-12 min-[220px]:max-[899px]:mx-0 min-[220px]:max-[899px]:p-0">
                                <img className="w-4/5 object-cover rounded-3xl object-[50%_40%] max-w-[1076px] min-[220px]:max-[899px]:w-full min-[220px]:max-[899px]:absolute min-[220px]:max-[899px]:h-full min-[900px]:max-[1440px]:w-1/2" src={item.image} alt={`Smukt Japansk Natur ${index + 1}`} />
                                <div className="w-1/2 px-6 bg-[#00000073] mr-4 rounded-3xl py-2 min-[220px]:max-[899px]:relative min-[220px]:max-[899px]:mr-0 min-[220px]:max-[899px]:w-screen min-[220px]:max-[899px]:h-full">
                                    <h2 className="text-4xl font-bold mb-4 break-words">{item.title}</h2>
                                    <p className="mb-8 font-semibold">{item.text}</p>
                                    <Link to={item.link} className="watch-btn">
                                        <span className="flex items-center z-[2] hover:underline">{item.cta}</span>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="dots absolute bottom-0 left-0 right-0 text-center mb-4 min-[220px]:max-[899px]:mb-0">
                        {carouselItems.map((_, index) => (
                            <span
                                key={index}
                                className={`dot inline-block w-3 h-3 mx-2 rounded-full overflow-hidden hover:cursor-pointer ${index === currentImageIndex ? "bg-red-500" : "bg-gray-300"}`}
                                onClick={() => handleDotClick(index)}
                            >
                                <span
                                    className="fill flex bg-red-700 h-full z-[2]"
                                    style={{ width: index === currentImageIndex ? `${fillPercentage}%` : '0%' }}
                                ></span>
                            </span>
                        ))}
                    </div>
                </div>
            </section>                        
            <section className="py-12">
                <div className="bg-[#00000073] py-2 px-6">
                    <h3 className="text-4xl font-bold mb-2">Rejse Højdepunkter</h3>
                    <div className="carousel-container relative flex overflow-hidden items-center">
                        <div className="carousel-images flex" style={{ transform: `translateX(-${startIndex * (100 / extendedHighlights.length)}%)`, transition: 'transform 0.5s ease-in-out' }}>
                            {extendedHighlights.map((highlight, index) => (
                                <div key={index} className="carousel-image">
                                    <Link to={highlight.link} className="items-center flex justify-center overflow-hidden">
                                        <img src={highlight.imageUrl} alt={highlight.month} />
                                        <span>Japan i {highlight.month}</span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className="absolute right-0 bottom-0 flex flex-col gap-2">
                            <button className="prev-button hover:bg-red-700" onClick={handlePrev}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="-scale-x-[1]" height="22" width="22" fill="#fff" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                            </button>
                            <button className="next-button hover:bg-red-700" onClick={handleNext}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" fill="#fff" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-[1140px] my-0 mx-auto pt-2 pb-8 px-6 bg-[#00000073]">
                    <h3 className="text-4xl font-bold mb-4">Dyk ned i det interessante Japan</h3>
                    <ul className="flex flex-wrap gap-4 justify-center">
                        {interests.map((inte, index) => (
                            <li key={index} className="interest-list">
                                <Link to={inte.link} className="flex relative justify-center items-center overflow-hidden rounded-md">
                                    <img src={inte.imageUrl} alt={inte.text} className="max-w-[249px]" />
                                    <span className="m-2">{inte.text}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>
    );
};

export default Home;