import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/may/may_hq.webp";
import content from "../../../images/japan_by_month/may/content.webp";

function GuideMaj() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i Maj';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i Maj</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Skarer og strålende varme</h3>
                    <p>Maj er Guldlok-området i den japanske kalender. Hverken for varmt eller for koldt, det er det perfekte tidspunkt at tage en tur, og næsten alle gør det. Den Gyldne uges ferie fra slutningen af April til begyndelsen af maj markerer toppen af denne periode med pakkede tog og hoteller som normen. Hvis du skal rejse i den første uge af maj, skal du sørge for at booke tidligt.</p>
                    <h3 className="font-bold mt-4">Blomster højdepunkter omkring Japan</h3>
                    <p>Bortset fra Hokkaido og det nordlige Tohoku, hvor de varer indtil midten af maj, kirsebærblomster er enten tyndet ud eller forsvundet i starten af måneden. Den gode nyhed er, at der er masser af andre blomstrende planter at nyde i forskellige dele af Japan.</p>
                    <p>I Tochigi-prefekturet er Ashikaga-blomsterparken vært for en blåregn-visningsbegivenhed fra midten af April til midten af maj. Den månedlange festival viser 160 år gamle blåregntræer, der er oplyst om natten for at give ekstra effekt. Inden for rækkevidde af Tokyo er det den perfekte måde at tilbringe en afslappet nat om foråret.</p>
                    <p>Et andet godt sted for blåregn er Kaachi Fuji blåregn haven i Fukuoka . I begyndelsen af maj kan Kaachi prale af en lang blåregnstunnel, der er perfekt til fotos.</p>
                    <p>Du kan finde andre blomster hotspots rundt omkring i Japan. Bjergskråningerne i Hitachi Seaside Park i Ibaraki kommer til live med den bløde blå af utallige Nemophila blomster (midten af April til slutningen af maj), mens i Hitsujiyama Park i Saitama pink og lilla shibasakura, eller mosfloks antænde markerne med strålende nuancer (midten af April til slutningen af maj).</p>
                    <h3 className="font-bold mt-4">Spring sumo og festival sjov i Tokyo</h3>
                    <p>May sumo-turneringen finder sted i Tokyo over to uger fra midten til slutningen af Maj, med forhåndsbilletter tilgængelige online fra begyndelsen af April. Den sidste dag lover mere drama end normalt, da de endelige resultater former konkurrenternes karriere og undertiden cementerer vigtige placeringer.</p>
                    <p>Hvis du er i hovedstaden omkring den tredje uge i Maj, kan du overveje at deltage i Sanja Matsuri ved Asakusa-Helligdommen. Denne store festivalbegivenhed, hvor bærbare helligdomme paraderes gennem gaderne, tiltrækker over en million mennesker årligt.<br/>På en mere rolig note er Tokyo Big Site vært for den årlige Designfest, hvor 10.000 kunstnere fra hele verden viser deres værker i et stort begivenhedsrum.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideMaj;