import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/shopping/shopping_hq.webp";
import content from "../../../images/japan_by_interests/shopping/content.webp";

function Shopping() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Shopping i Japan';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Shopping i Japan</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Gå gennem gaderne i smarte shoppingkvarterer i byen eller jagt på traditionelle varer i landets landlige områder</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Japans dybt forankrede tradition for håndværk har givet japanske produkter et ry for høj kvalitet over hele verden</h3>
                    <p>Fra kærligt brygget sake og værdsat porcelæn, der er fremstillet på samme måde i århundreder, til de allernyeste gadgets og den hotteste mode, vil du finde det nær umuligt at forlade landet tomhændet. Opdag hvor du kan shoppe, og hvad du skal købe. Og forvent skattefrie tilbud, limited edition-udvalg og unikke fund.</p>
                    <h3 className="font-bold mt-4">Shoppingparadis: Opdag Japans Unikke Detailhandelsoplevelser</h3>
                    <p>Dyk ned i en verden af ​​unikt håndværk og moderne innovation, når du udforsker Japans butikker og markeder. Fra traditionelle gader fyldt med antikviteter til futuristiske stormagasiner, Japan tilbyder en uovertruffen shoppingoplevelse. Find ud af, hvor du kan finde de bedste souvenirs, det mest fashionable tøj og de mest innovative gadgets, mens du nyder skattefri shopping, limited edition-tilbud og eksklusive fund.</p>
                    <h3 className="font-bold mt-4">Skattefri Shopping i Japan: En Guide til Toldfrie Tilbud og Unikke Fund</h3>
                    <p>Tag fordel af Japans skattefrie shoppingmuligheder og spar penge på dine køb. Udforsk de bedste shoppingsteder, hvor du kan opdage alt fra traditionelle kunsthåndværk til moderne elektronik. Lær, hvordan du navigerer i toldfri shoppingprocessen og finder de mest eksklusive tilbud på limited edition-varer og unikke souvenirs.</p>
                    <h3 className="font-bold mt-4">Fra Gadebutikker til Luksusbutikker: En Rejsendes Guide til Shopping i Japan</h3>
                    <p>Oplev mangfoldigheden af ​​shoppingmuligheder i Japan, fra hyggelige gadebutikker til overdådige luksusbutikker. Udforsk Tokyos pulserende shoppingdistrikter, Kyoto's traditionelle markeder og Osaka's trendy butikker. Opdag hvor du kan finde alt fra håndlavede kunstværker til internationale modebrands, og lad dig forføre af Japans unikke shoppingkultur, der byder på noget for enhver smag og ethvert budget.</p>
               </div>
            </div>
        </section>
    );
};

export default Shopping;