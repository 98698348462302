import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/april/april_hq.webp";
import content from "../../../images/japan_by_month/april/content.webp";

function GuideApril() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i April';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i April</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Foråret rammer sit skridt</h3>
                    <p>Foråret bryder virkelig ud i livet i April, da den korte periode med blomstrende kirsebærtræer giver plads til behageligt grønt løv og mildere temperaturer. Det er en afslappet tid, hvor du kan nøjes med lette jakker og langærmede skjorter. Forskning er et must, når man planlægger en tur for at se kirsebærblomsten, da spidsbelastningsperioder varierer betydeligt efter sted såvel som år til år.</p>
                    <h3 className="font-bold mt-4">Afslappende under blomsterne</h3>
                    <p>Japans regnskabsår slutter i en strøm af aktivitet i Marts 31, efterlader meget af befolkningen i humør til at sparke tilbage. Hanami-fester giver fritidsaktiviteten fremragende, som festende strækker sig ud på presenninger for at tilbringe tid med venner og familie. Mens de fleste byer har en gå til stedet, kan du nyde Hanami-oplevelsen i enhver park eller offentligt rum, hvor kirsebærtræerne blomstrer.</p>
                    <p>Når du planlægger dit besøg, skal du huske på, at kirsebærblomstringsperioden er kort på et givet sted, men ret lang på nationalt plan. Den begynder i Januar og varer langt ind i Maj i Hokkaido og bjergområder. Du kan følge blomstringen af kirsebærblomsten, når de rejser op i landet.</p>
                    <h3 className="font-bold mt-4">Cherry blossoms og Mt Fuji</h3>
                    <p>Kajaguchi-søen og Fujiyoshida er måske ikke kendte navne uden for Japan, men begge er ikoniske steder hvor man kan se blomster nær Mt. Fuji. Den første har en attraktiv beliggenhed ved søen, mens den anden har en berømt pagode, der ofte er fotograferet og fremhævet i reklamemateriale. Blomsterne i disse områder er normalt bedst mellem begyndelsen og midten af April.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideApril;