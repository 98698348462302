import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/october/october_hq.webp";
import content from "../../../images/japan_by_month/october/content.webp";

function GuideOkt() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i Oktober';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i Oktober</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Det bedste af efteråret</h3>
                    <p>Med tyfonsæsonen stort set forbi i slutningen af September, oktober byder på noget af Japans smukkeste og mest behagelige vejr. Det er en tid med høst og høst festivaler, udendørs udflugter, høstlige vandreture og kunst og kultur.</p>
                    <h3 className="font-bold mt-4">En smag af efteråret</h3>
                    <p>Prime høsttid, mad smager godt i oktober, fra japanske pærer og persimmonfrugt til ris, soba nudler og skyld. Niigata, en top-sake og risproducerende region, er et godt sted at prøve nogle af de sæsonbestemte sake-sorter og endda få et glimt af rishøsten i aktion.</p>
                    <p>De maleriske hoshitoge TANADA rismarkterrasser ser godt ud om efteråret, og risen høstes generelt indtil midten af oktober. For finurlige ris felt kunst, hoved til Inakadate landsby i Aomori: det er blevet en internet sensation i de seneste år.</p>
                    <h3 className="font-bold mt-4">Fejrer høsten</h3>
                    <p>Der er festivaler hele året i Japan, og oktober måned er ingen undtagelse. Mange af dem har udviklet sig fra enkle fejringer af høsten til festivaler med de farverige parader og processioner, du kan se i dag. Takayama efterårsfestivalen den 9. og 10. Oktober er en særlig imponerende affære.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideOkt;