import React, { useEffect } from "react";
import banner from "../../../images/carousel/slide_4/japanese_culture.jpg";
import geisha from "../../../images/carousel/slide_4/cultural_exp/geisha.jpg";

function CulturalExp() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Kultur';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">En smag for livet:</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Dyk ned i Japans Kulturelle Rigdomme</h2>
                    <p className="font-bold text-xl text-white max-w-[900px] drop-shadow-[2px_4px_6px_black] max-[425px]:text-lg max-[425px]:mt-4">Udforsk historiske skatte, fordyb dig i ældgamle kunstformer og lad dig fortrylle af den unikke japanske sjæl.</p>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={geisha} alt="Two Geisha sitting on the floor" />
                    <p>Velkommen til Japan, et land med en utrolig rig kulturarv og en dybde af kulturelle oplevelser, der venter på at blive udforsket. Når du rejser gennem dette fascinerende land, vil du opdage en skatkiste af traditioner, kunstformer og skikke, der strækker sig tilbage århundreder.</p>
                    <h3 className="font-bold mt-4">Traditionel Kunst og Håndværk:</h3>
                    <p>Oplev Japans stolte håndværkstraditioner, der spænder fra den delikate keramik fra Kyoto til den ædle kimono fra gamle Edo-perioden. Udforsk kunstformer som ikebana (blomsterarrangement), chado (teceremoni) og origami (papirkunst), der ikke kun er kunstneriske udtryk, men også bærere af dyb symbolik og filosofi.</p>
                    <h3 className="font-bold mt-4">Templer og Helligdomme:</h3>
                    <p>Gå på opdagelse i Japans mangfoldige religiøse landskab ved at besøge de utallige templer og helligdomme, der punkterer landskabet. Fra det majestætiske Nikko Toshogu Shrine til det fredelige Ryoanji Temple i Kyoto, vil du blive indhyllet i en atmosfære af spiritualitet og historie.</p>
                    <h3 className="font-bold mt-4">Madkultur og Gastronomi:</h3>
                    <p>Japan er kendt verden over for sin rige madkultur og gastronomiske mesterværker. Udforsk de forskellige regionale køkkener, fra de subtile smagsnuancer i Kaiseki-ryori til den hjertelige komfort af ramen. Gå ikke glip af muligheden for at deltage i en sushi-mesterklasse eller at smage autentisk tempura fra en lokal madbod.</p>
                    <h3 className="font-bold mt-4">Traditionelle Festivaler:</h3>
                    <p>Fordyb dig i festlige atmosfærer og farverige ritualer ved at deltage i traditionelle festivaler, der fejres året rundt i hele landet. Fra de spektakulære fyrværkeri på sommerfestivaler til de rolige og reflekterende atmosfærer ved Nara's Omizutori-festival, er der altid en begivenhed, der fejrer Japans kulturelle arv.</p>
                    <h3 className="font-bold mt-4">Popkultur og Moderne Udforskning:</h3>
                    <p>Tag på opdagelse i Japans moderne kultur, der spænder fra manga og anime til avanceret teknologi og arkitektur. Udforsk travle gader i Akihabara, besøg futuristiske museer som teamLab Borderless og oplev Japans unikke fusion af tradition og innovation.</p>
                    <p>Velkommen til et land, hvor fortiden møder nutiden på en unik og inspirerende måde. Uanset om du er interesseret i historie, kunst, mad eller teknologi, vil Japan forbløffe dig med sin dybe kulturelle rigdom og give uforglemmelige oplevelser for livet.</p>
                </div>
            </div>
        </section>
    );
};

export default CulturalExp;