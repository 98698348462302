import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/history/history_hq.webp";
import content from "../../../images/japan_by_interests/history/content.webp";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japans Historie';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japans Historie</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Besøg slotte, templer, byer berømte for frygtløse krigere og landsbyer, der blomstrede som træningscentre for ninjaer</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Udforsk verdensarvssteder, herunder Himeji Castle, Hiroshima Peace Memorial Park og de historiske landsbyer Shirakawa-go og Gokayama og mere. Mens alle disse steder er slående eksempler på japansk historie, opdag mange andre steder spredt rundt om i landet, hvor du kan træde ind i Japans fortid.</h3>
                    <p>Aizuwakamatsu i Fukushima Præfekturet er kendt som samuraiernes land. Du kan besøge mange steder, der viser samurai-kulturen, herunder Tsurugajo Castle. Hvis du er interesseret i at udforske hjemmet for den mere stille, men lige så nådesløse gruppe af lejesoldater - ninjaerne - skal du tage til landsbyen Iga Ueno i Mie Præfekturet eller regionen Koka i Shiga Præfekturet.</p>
                    <p>De sydlige øer i Okinawa har en rig historie, der er helt anderledes end på fastlandet. Besøg de stenmure fra den tabte Ryukyu-æra og opdag ekstraordinære kunstneriske, musikalske og kulinariske traditioner.</p>
                    <h3 className="font-bold mt-4">Dybt Ind i Fortiden: En Rejsendes Guide til Japans Historiske Skatte</h3>
                    <p>Træd ind i Japans rige historie og opdag skjulte skatte, der vidner om landets fascinerende fortid. Udforsk de ikoniske Himeji Castle og Hiroshima Peace Memorial Park samt de ældgamle landsbyer Shirakawa-go og Gokayama. Rejs til Fukushima Præfekturet og oplev samuraiernes land, eller dyk ned i ninjaernes mysterium i Mie og Shiga Præfekturer. Lad dig fortrylle af Okinawas unikke kultur og historie, der skiller sig ud fra fastlandet.</p>
                    <h3 className="font-bold mt-4">Spor af Samuraier og Ninjaer: En Eventyrers Guide til Japans Historiske Steder</h3>
                    <p>Tag med på en rejse gennem Japans dramatiske fortid, hvor samuraier og ninjaer regerede landet. Udforsk Tsurugajo Castle i Aizuwakamatsu, oplev samuraiernes æra og gå på opdagelse i de skjulte ninja-landsbyer i Iga Ueno og Koka. Opdag hemmelighederne bag Okinawas tabte Ryukyu-æra og lad dig forføre af øens unikke kunstneriske og kulinariske traditioner.</p>
                    <h3 className="font-bold mt-4">Fra Oldtiden til Moderne Tider: En Rejsendes Dyk ned i Japans Historie</h3>
                    <p>Gør dig klar til en rejse gennem tidens lag i Japan, hvor fortidens mystik møder nutidens puls. Udforsk Japans UNESCO-verdensarvssteder, herunder Himeji Castle og Hiroshima Peace Memorial Park, og dyk ned i de historiske landsbyer Shirakawa-go og Gokayama. Oplev samuraiernes ære i Fukushima Præfekturet og ninjaernes hemmeligheder i Mie og Shiga Præfekturer. Lad dig forundre af Okinawas unikke kultur, der fortæller en historie helt sin egen.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;