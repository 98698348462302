import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/june/june_hq.webp";
import content from "../../../images/japan_by_month/june/content.webp";

function GuideJuni() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i Juni';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i Juni</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Den regnfulde start på sommeren</h3>
                    <p>Traditionally called minazuki, "the month of water," June gets more rainfall than other months as the rainy season sets in and sweeps upward through Japan. As with cherry blossom season, rainy season begins and ends earlier in the south and starts later and lasts longer in the north. Hokkaido , relatively unaffected by the rainy season, is a safe bet if you want to avoid the rain almost entirely.</p>
                    <h3 className="font-bold mt-4">Marker fyldt med vand og modne blommer</h3>
                    <p>Det japanske ord for regntiden, tsuyu eller" blommeregn", henviser til en periode, hvor blommer modnes på træer, da rismarker omkring landdistrikterne i Japan oversvømmes af regn. Denne tid af året bestemmer rishøsten, og er på en måde livslinjen for Japans befolkning. En anden mærkbar fordel ved sæsonen kan ses i de lysende hortensiaer, der blomstrer i det fugtige vejr.</p>
                    <p>Ikke alle regntider er dog skabt ens: med lav årlig nedbør, Hokkaido ser en minimal stigning i regntiden, mens de sydlige øer Kyushu og Shikoku bliver ekstremt våde. Omhyggelig planlægning kan hjælpe dig med at forblive relativt tør i Juni.</p>
                    <h3 className="font-bold mt-4">Kunst og underholdning indendørs</h3>
                    <p>For en definitiv kulturel oplevelse, Besøg Kabukia for en kabuki-forestilling. Entusiaster kan vælge et par timer planlagt med flere dramaer udført, mens mere afslappede tilskuere måske foretrækker at se kun en. Bagefter, du kan pop din paraply og granske områdets massive stormagasiner og rigelige kulinariske muligheder.</p>
                    <p>Ueno tilbyder et imponerende antal museer, der ofte er vært for store kunstudstillinger fra hele verden. Fra store franske impressionister til klassiske antikviteter, Ueno har været vært for næsten alt, hvad du kan forestille dig gennem årene.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJuni;