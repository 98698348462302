import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/january/january_hq.webp";
import content from "../../../images/japan_by_month/january/content.webp";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i Januar';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i Januar</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">En lys, farverig og kold start på året</h3>
                    <p>Januar er en af de koldere måneder på kalenderen. En stigning a rejsende i feriesæsonen kan gøre det vanskeligt at bestille billetter og indkvartering tidligt på måneden. Det er dog et godt tidspunkt at opleve japansk kultur såsom besøg til helligdomme, templer og andre offentlige begivenheder. Det er også en god tid til vintersport og afslappende ophold i varme kilder.</p>
                    <h3 className="font-bold mt-4">Oplev Oshogatsu</h3>
                    <p>Japansk nytår er hovedsagelig en familieferie, men der er flere måder for besøgende at nyde det. Hatsumode er praksisen med at besøge helligdomme og templer i begyndelsen af året. Selvom din interesse er rent sekulær, gør den festlige atmosfære og madboder værd at give et besøg.</p>
                    <p>En helligdom med en særlig farverig nytårsfest er Itsukushima-Helligdommen i Miyajima, Hiroshima. Fra den 1. til den 3. januar og også den 5. januar udføres traditionel dansk og musik, der tiltrækker store skarer. For den fulde Nytår oplevelse, kan du rejse til øen den 31 december for at opleve Chinka-sai Festivalen og se den første solopgang den efterfølgende dag fra toppen af Mt Misen eller andre steder på øen.</p>
                    <h3 className="font-bold mt-4">Shop til du falder om</h3>
                    <p>Snesevis af mennesker står i linje op foran stormagasiner og andre butikker med håb om at få en større discount på et ønsket produkt. En af de mere unikke metoder er fukubukuro, en uigennemsigtig pose fyldt med flere varer til en samlet værdi langt ud over deres undividuelle priser.</p>
                    <p>Fra den 1. eller 2. januar åbner butikkerne deres døre for ivrige kunder i de store nervecentre i Tokyo, som f.eks. Ginza, Shibuya, Harajuku, og Aoyama. Ginza tilbyder luksus produkter hvor Shibuya henvender sig til en yngre generation. Tjek de individuelle butiksåbningstider for at have en chance for at fange en af de bedste tilbud.</p>
                    <h3 className="font-bold mt-4">Kommer af alder ritualer</h3>
                    <p>Efter varmt på hælene på nytår, fejres Coming of Age Day i Japan den anden mandag i januar i byen, by- og landsbyhuse of offentlige faciliteter over hele landet. Som med andre familieorienterede helligdage er det ikke noget, du kan deltage direkte i, men du kan se gruppe af kvinder, der kommer i alderen iført dyre kimonoer og eksotiske frisurer og deltager i samfundsbegivenheder, og der er nogle steder hvor begivenheden får en mere offentlig dimension.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;