import React, { useEffect, useState } from "react";
import banner from "../images/destination_tab/banner.webp";
import hokkaido from "../images/destination_tab/hokkaido.webp";
import hokkaido_land from "../images/destination_tab/hokkaido_land.svg";
import tohoku from "../images/destination_tab/tohoku.webp";
import tohoku_land from "../images/destination_tab/tohoku_land.svg";
import hokuriku from "../images/destination_tab/hokkaido.webp";
import hokuriku_land from "../images/destination_tab/hokkaido_land.svg";
import kanto from "../images/destination_tab/kanto.webp";
import kanto_land from "../images/destination_tab/kanto_land.svg";
import tokai from "../images/destination_tab/tokai.webp";
import tokai_land from "../images/destination_tab/tokai_land.svg";
import kansai from "../images/destination_tab/kansai.webp";
import kansai_land from "../images/destination_tab/kansai_land.svg";
import chugoku from "../images/destination_tab/chugoku.webp";
import chugoku_land from "../images/destination_tab/chugoku_land.svg";
import shikoku from "../images/destination_tab/shikoku.webp";
import shikoku_land from "../images/destination_tab/shikoku_land.svg";
import kyushu from "../images/destination_tab/kyushu.webp";
import kyushu_land from "../images/destination_tab/kyushu_land.svg";
import okinawa from "../images/destination_tab/okinawa.webp";
import okinawa_land from "../images/destination_tab/okinawa_land.svg";

function Destinationer() {
    const [selectedDestination, setSelectedDestination] = useState(null);

    const interests = [
        { 
            name: "Hokkaido",
            title: "Japan's nordlige ø er berømt for sin smukke vildmark, verdensklasse puddersne, lækre skaldyr og fascinerende oprindelige kultur. Kun en kort flyvning fra Tokyo er Hokkaido det perfekte tilflugtssted i alle årstider.",
            title2: "Hvordan kommer man derhen?",
            info: "Hokkaido er et populært rejsemål for skiløb og snowboarding om vinteren, takket være sin overflod af puddersne og topferiesteder som Niseko, Rusutsu og Furano. Sapporo, Hokkaidos største by, er berømt for Sapporo-øl, Sapporo-ramen og det årlige Sapporo Snow Festival. Hakodate er en traditionel havn med charmerende historiske områder og fantastiske naturskønne udsigter om natten. Hokkaidos milde somre giver lettelse fra Japans fugtighed og de perfekte betingelser for vandreture og udforskning af de mange nationalparker. Meget af Hokkaido er vild og uberørt med naturlige varme kilder, vulkanske søer og uforglemmelige oplevelser. Udforsk kulturen og traditionerne hos de indfødte Ainu-folk ved Lake Akan og tag på rejse til Shiretoko-halvøen for at se fritløbende brune bjørne, ræve og hjorte.",
            info2: "Hokkaido er tilgængelig med fly fra alle større lufthavne i Japan til New Chitose Lufthavn nær Sapporo, med internationale fly fra Shanghai og Seoul. Direkte fly fra Tokyo tager cirka 90 minutter. Der er også flere internationale flyvninger. Det er muligt at tage Shinkansen fra Tokyo. Japan Rail Pass kan bruges til at rejse med Shinkansen. Den hurtigste måde at nå Hokkaido på er med fly. Fly fra Tokyo til Sapporos New Chitose Lufthavn tager cirka 90 minutter. Med tog tager det cirka otte timer fra Tokyo. Tag JR Tohoku/Hokkaido Shinkansen fra Tokyo til Shin-Hakodate-Hokuto og skift derefter til Hokuto Limited Express til Sapporo. Der er også nogle billige langdistancefærger fra Honshu til havne uden for Sapporo. Niigata, Oarai (Ibaraki-præfekturet), Sendai, Nagoya, Maizuru (Kyoto-præfekturet) og Tsuruga (Fukui-præfekturet) har alle færger, der går til Hokkaido.",
            imageUrl: hokkaido,
            landSVG: hokkaido_land
        },
        { 
            name: "Tohoku",
            title: "Tohoku-regionen i Japan er hjemsted for uberørte landlige landskaber og historiske skatte, udendørs sommeroplevelser og endeløs puddersne om vinteren",
            title2: "Udforsk Tohoku efter præfektur",
            info: "Det nordøstlige vilde landskab i Japan er en enorm region, der omfatter seks landlige præfekturer, bundet sammen af skikke, arv og nylige tragedier. Det ødelæggende jordskælv og tsunami, der ramte i 2011, fremhævede Tohokus befolknings modstandsdygtige natur, og lokal lidenskab og stolthed får området på benene igen. Der er meget at være stolt af – dramatiske landskaber, historie-rige steder, håndværkssake og en række udendørs aktiviteter som rafting, vandreture og skiløb. Meget mere landligt end Tokyo, men mere udviklet end Hokkaido, gør Tohoku-regionen spændende udflugter fra hovedstaden og mindeværdige sideudflugter på rejsen til Japans nordligste region på hovedøerne.",
            info2: "Tohoku består af præfekturene Aomori, Akita, Iwate, Yamagata, Miyagi og Fukushima.",
            imageUrl: tohoku,
            landSVG: tohoku_land
        },
        { 
            name: "Hokuriku Shinetsu",
            title: "Bjergene og havet mødes i denne vilde region, og resultatet er ren skønhed",
            title2: "Udforsk Hokuriku Shinetsu efter præfektur",
            info: "Med den praktiske højhastigheds-togforbindelse, der forbinder Tokyo med størstedelen af Hokuriku Shinetsu på to en halv time eller mindre, er de majestætiske bjerge i de japanske alper, udsigten til Japans vestkyst samt byer og byer, der trives med raffineret kultur, alle inden for nem rækkevidde. Af Hokurikus fem præfekturer er Nagano den eneste uden en kystlinje, men sammen med Niigata tilbyder den nogle af de bedste skiløb og snowboarding i Japan. Toyama og Fukui præfekturer præsenterer et forførende og tiltalende stykke af det rurale Japan, mens Ishikawa er hjemsted for kunst og kultur, med dens hovedstad Kanazawa som centrum for både traditionelt og moderne japansk design.",
            info2: "Tohoku består af præfekturene Niigata, Toyama, Ishikawa, Fukui og Nagano.",
            imageUrl: hokuriku,
            landSVG: hokuriku_land
        },
        { 
            name: "Kanto",
            title: "Med Tokyo som ankerpunkt, verdens største metropol, har Kanto også rustikke bjergferiesteder og smukke subtropiske øer",
            title2: "Udforsk Kanto efter præfektur",
            info: "Megabyen Tokyo engagerer alle sanser med sin blanding af moderne travlhed og traditionel ro. Men før du flyver afsted til Kyoto eller længere væk, så tillad dig selv at opdage Kantos varme kilder, uberørte bjergkløfter, uberørte strande, UNESCOs verdensarvssteder og kulturelle skatte. Den mangfoldige havneby Yokohama og den dybdegående natur i Gunma, Saitama og Ibaraki ligger lige ved Tokyo's dørtrin. De vulkanske Ogasawara-øer tilbyder en subtropisk oplevelse med endemisk dyreliv og klart vand. Kantos kulinariske muligheder er lige så varierede som landskabet - restauranter der serverer køkken fra hvert hjørne af kloden findes i alle prisklasser.",
            info2: "Kanto består af præfekturene Tokyo, Kanagawa, Chiba, Saitama, Ibaraki, Tochigi og Gunma.",
            imageUrl: kanto,
            landSVG: kanto_land
        },
        { 
            name: "Tokai",
            title: "Selvom den er betjent af Shinkansen mellem Tokyo og Kyoto, byder Tokai-regionen både på verdensberømte destinationer og lokalt pragt",
            title2: "Udforsk Tokai efter præfektur",
            info: "En quintessentially japansk region, præfekturerne der udgør Tokai blander ældgammel arv, moderne industri og fantastisk natur. Mt. Fuji, flankeret af de varme kilder i Hakone og Fuji Five Lakes, er en af verdens mest ikoniske tinder. Tokai omfavner både fortiden og ser mod fremtiden med verdensarvsstedet Shirakawa-go og dets stråtækte hytter, landets største grønne teplantager, Toyota Motor Corp. og Ise-Jingu, det mest højtidelige tempel i Japan.",
            info2: "Tokai består af præfekturene Yamanashi, Shizuoka, Gifu, Aichi og Mie.",
            imageUrl: tokai,
            landSVG: tokai_land
        },
        { 
            name: "Kansai",
            title: "Der er mange ting at lave i Kansai-regionen. Oplev fantastisk natteliv, fredelige templer og helligdomme og sjælesøgende pilgrimsrejser",
            title2: "Udforsk Kansai efter præfektur",
            info: "Kansai er Japans åndelige og kulturelle hovedstad, formet af de storslåede borge Osaka og Himeji, zen-haver og strabadserende rejser mod oplysning. Nationens gamle hovedstæder Kyoto og Nara er fyldt med kulturelle og historiske steder, mens Koyasan i Wakayama har været et religiøst rejsemål i århundreder. I nærheden kan du nyde det larmende natteliv i Osakas underholdningskvarterer og nyde panoramiske udsigter over Kobe bybillede.",
            info2: "Kansai består af præfekturene Kyoto, Osaka, Shiga, Hyogo, Nara og Wakayama.",
            imageUrl: kansai,
            landSVG: kansai_land
        },
        { 
            name: "Chugoku",
            title: "For dem der ved det, er Chugoku-regionen fuld af naturskønhed, belønnende vandreture og stor historisk betydning",
            title2: "Udforsk Chugoku efter præfektur",
            info: "Med sin velkendte historie og berømte flydende helligdom er det svært at overse Hiroshima, men dette populære rejsemål er blot indgangspunktet til det vestlige Japan for dem, der er inde i det. Fortsæt mod vest langs Sanyo Shinkansen, og du vil opdage idylliske kystbyer og ø-hoppe muligheder på Seto Inland Sea, med videre stier til Kyushu og Shikoku. Tag ind i landet ad den mindre befærdede vej, og dine bestræbelser vil blive belønnet med vandreture på betagende tinder som Mt. Daisen guddommelige naturlandskaber og spændingen ved opdagelsen i Shimane og Tottori.",
            info2: "Chugoku består af præfekturene Tottori, Shimane, Okayama, Hiroshima og Yamaguchi.",
            imageUrl: chugoku,
            landSVG: chugoku_land
        },
        { 
            name: "Shikoku",
            title: "At give plads til litterære klassikere, feberhede danse, naturvidundere og en gammel pilgrimsrute",
            title2: "Udforsk Shikoku efter præfektur",
            info: "Shikoku er måske den mindste af Japans fire største øer, men den har en stor naturlig og kulturel betydning. Den praler af en malerisk kystlinje langs den glitrende Seto Indlandshav, legendariske udon nudler, film-inspirerende onsen, det første skridt på Shikoku 88 Tempel Pilgrimsrejse og en sommer scene for Japan at danse. Det er også indstillingen for den japanske litterære gigant Natsume Sosekes klassiker, 'Botchan.' Hvis du leder efter at dykke ned i et mere traditionelt Japan, er det tid til at udforske livlige Shikoku.",
            info2: "Shikoku består af præfekturene Tokushima, Kagawa, Ehime og Kochi.",
            imageUrl: shikoku,
            landSVG: shikoku_land
        },
        { 
            name: "Kyushu",
            title: "Nemt tilgængelig via land, hav og luft, bobler den dynamiske Kyushu af energi, kultur og aktivitet",
            title2: "Udforsk Kyushu efter præfektur",
            info: "Japans tredjestørste ø er internationalt kendt for sin tonkotsu-ramen, varierede varme kilder, dramatiske bjerge og fredelige strande. Mens opstartshuben Fukuoka bobler af international opmærksomhed, fortsætter det vulkanske terræn mod syd med at rumle og ryge. Den seismiske aktivitet har skabt et klippefuldt eventyrland af otte dampende varme kildområder, kendt samlet som Beppu Onsen, samt høje tinder at vandre på, såsom Mt. Karakuni i Kirishima-bjergkæden. Ved at tilbyde en smag af både topmoderne modernitet og langsomt tempo, er Japans sydlige ø bedst udforsket i et afslappet tempo. Tag mod syd for at slappe af på en ø sprængfyldt med spektakulær natur, kultur og køkken.",
            info2: "Kyushu består af præfekturene Fukuoka, Saga, Nagasaki, Oita, Kumamoto, Miyazaki og Kagoshima.",
            imageUrl: kyushu,
            landSVG: kyushu_land
        },
        { 
            name: "Okinawa",
            title: "Opdag en unik økultur født af subtropisk sol, hvidt sand, mangrovejungler og tiden for Ryukyu-kongerne",
            title2: "Hvordan kommer man derhen?",
            info: "Japan's sydligste præfektur, Okinawa, er en kæde af øer med sin egen historie som et uafhængigt kongerige og et tydeligt subtropisk klima, samt fødestedet for karate. Udforsk ruinerne og restaurerede slotte af Ryukyu-kongerne, og besøg pragtfulde strande og kystlinjer med et fantastisk udvalg af koralrev og undervandsliv. Kom til hvalkiggeri og dragebådsløb, sjælden flora og fauna, og en ø-mentalitet, der får dig til at glemme uret og følge solen.",
            info2: "Fra fastlandet Japan afgår de fleste flyvninger til Okinawas Naha Lufthavn fra Tokyo eller Osaka. Hvis du kommer fra Kina, Sydkorea, Singapore, Taiwan eller Hongkong, er der direkte internationale flyvninger til Okinawa. Der er hyppige flyvninger fra Tokyo til Naha på Okinawas hovedø hver dag samt direkte flyvninger til den yderligere ø Ishigaki. Der er også sæsonbetonede direkte flyvninger til Miyako Island. Du kan også tage forbindelsesflyvninger til de yderligere øer Miyako, Ishigaki, Yonaguni og Kume fra Naha Lufthavn. Den bedste måde at komme rundt på Okinawa er med lejebil, eller med cykel eller scooter i visse områder. Hvis du foretrækker offentlig transport, er der en monorail, der kører fra Naha Lufthavn gennem Naha til den gamle hovedstad Shuri. Der er også en omfattende busbetjening på hovedøen, og færgeservicer kører mellem nærliggende øer.",
            imageUrl: okinawa,
            landSVG: okinawa_land
        },
    ];

    const handleClick = (destination) => {
        setSelectedDestination(destination);
        // Scroll to the section with the info
        if (document.getElementById("destination-info")) {
            document.getElementById("destination-info").scrollIntoView({ behavior: "smooth" });
        } else {
            setTimeout(() => {
                document.getElementById("destination-info").scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
    };

    useEffect(() => {
        document.title = 'Ryoko | Destinationer i Japan';
    }, []);
    
    return (
        <>
            <section>
                <div className="hero-banner mt-4 relative flex">
                    <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Green Trees" />
                    <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                        <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Udforsk Japan</h1>
                        <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Destinationer</h2>
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-[1140px] my-4 mx-auto pt-2 pb-8 px-6 bg-[#00000073] rounded-lg">
                    <h3 className="text-4xl max-[425px]:text-2xl font-bold mb-4">Fra det store hvide nord til det subtropiske syd og alt derimellem, klik på billederne for at opdage mere!</h3>
                    <ul className="flex flex-wrap gap-4 justify-center">
                        {interests.map((interests) => (
                            <li key={interests.name} className="interest-list">
                                <button onClick={() => handleClick(interests)} className="flex relative justify-center items-center overflow-hidden rounded-md">
                                    <img src={interests.imageUrl} alt={interests.name} className="max-w-[249px] brightness-75" />
                                    <div className="absolute flex flex-col">
                                        <img src={interests.landSVG} alt={interests.name} className="max-h-[75px]"/>
                                        <span className="m-2 text-white [text-shadow:_2px_4px_6px_black] text-2xl font-bold">{interests.name}</span>
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
            {selectedDestination && (
                <section id="destination-info">
                    <div className="max-w-[1140px] mb-4 mx-auto pt-2 pb-8 px-6 bg-[#00000073] rounded-lg">
                        <h3 className="text-4xl font-bold my-4 border-b-2 border-solid">{selectedDestination.name}</h3>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title}</h4>
                        <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={selectedDestination.imageUrl} alt={selectedDestination.name} />
                        <p className="mb-8 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info}</p>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title2}</h4>
                        <p className="mb-4 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info2}</p>
                    </div>
                </section>
            )}
        </>
    );
}
export default Destinationer;