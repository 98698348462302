import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/App.css';
// Components
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import AccessibilityBar from './components/AccessibilityBar';
import CookieBanner from './components/CookieBanner';
import ScrollToTop from './components/ScrollToTop';
// Header Nav
import Destinationer from './components/destinationer';
import Oplevelser from './components/oplevelser';
// Eye Catchers (First Carousel - home.jsx)
import Sakura from './components/pages/eye_catchers/sakura';
import Adventures from './components/pages/eye_catchers/adventures';
import UniqueExp from './components/pages/eye_catchers/unique_exp';
import CulturalExp from './components/pages/eye_catchers/cultural_exp';
// Guides by month
import GuideJan from './components/pages/months/jan';
import GuideFeb from './components/pages/months/feb';
import GuideMarts from './components/pages/months/marts';
import GuideApril from './components/pages/months/april';
import GuideMaj from './components/pages/months/maj';
import GuideJuni from './components/pages/months/juni';
import GuideJuli from './components/pages/months/juli';
import GuideAug from './components/pages/months/aug';
import GuideSep from './components/pages/months/sep';
import GuideOkt from './components/pages/months/okt';
import GuideNov from './components/pages/months/nov';
import GuideDec from './components/pages/months/dec';
// Interests
import Attraktioner from './components/pages/interests/attraktioner';
import Kultur from './components/pages/interests/kultur';
import Afslappelse from './components/pages/interests/afslappelse';
import Shopping from './components/pages/interests/shopping';
import Kunst from './components/pages/interests/kunst';
import Historie from './components/pages/interests/historie';
import Natur from './components/pages/interests/natur';
import Events from './components/pages/interests/events';
import Madogdrikke from './components/pages/interests/madogdrikke';
import Parker from './components/pages/interests/parker';
import Kulturarv from './components/pages/interests/kulturarv';
import Eventyr from './components/pages/interests/eventyr';

function App() {
  return (
    <Router>
      <Header />
      <div className="App">
      <AccessibilityBar />
      <CookieBanner />
      <ScrollToTop />
        <Routes>
          {/*Header nav*/}
          <Route path='/' element={<Home />} />
          <Route path='/destinationer' element={<Destinationer />} />
          <Route path='/oplevelser' element={<Oplevelser />} />
          {/*Carousel*/}
          <Route path='/sakura' element={<Sakura />} />
          <Route path='/adventures' element={<Adventures />} />
          <Route path='/uniqueexp' element={<UniqueExp />} />
          <Route path='/culturalexp' element={<CulturalExp />} />
          {/* Guides by month */}
          <Route path='/guidejan' element={<GuideJan />} />
          <Route path='/guidefeb' element={<GuideFeb />} />
          <Route path='/guidemarts' element={<GuideMarts />} />
          <Route path='/guideapril' element={<GuideApril />} />
          <Route path='/guidemaj' element={<GuideMaj />} />
          <Route path='/guidejuni' element={<GuideJuni />} />
          <Route path='/guidejuli' element={<GuideJuli />} />
          <Route path='/guideaug' element={<GuideAug />} />
          <Route path='/guidesep' element={<GuideSep />} />
          <Route path='/guideokt' element={<GuideOkt />} />
          <Route path='/guidenov' element={<GuideNov />} />
          <Route path='/guidedec' element={<GuideDec />} />
          {/* Interests */}
          <Route path='/attraktioner' element={<Attraktioner />} />
          <Route path='/kultur' element={<Kultur />} />
          <Route path='/afslappelse' element={<Afslappelse />} />
          <Route path='/shopping' element={<Shopping />} />
          <Route path='/kunst' element={<Kunst />} />
          <Route path='/historie' element={<Historie />} />
          <Route path='/natur' element={<Natur />} />
          <Route path='/events' element={<Events />} />
          <Route path='/madogdrikke' element={<Madogdrikke />} />
          <Route path='/parker' element={<Parker />} />
          <Route path='/kulturarv' element={<Kulturarv />} />
          <Route path='/eventyr' element={<Eventyr />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
