import React, { useEffect } from "react";
import banner from "../../../images/carousel/slide_2/things_to_do_in_japan.jpg";
import tokyo from "../../../images/carousel/slide_2/adventures/tokyo-main.jpg";

function Adventures() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Eventyr';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japan Adventures" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Oplev Japan:</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Et Uforglemmeligt Eventyr</h2>
                    <p className="font-bold text-xl text-white max-w-[900px] drop-shadow-[2px_4px_6px_black] max-[425px]:text-lg max-[425px]:mt-4">Oplev skønheden, kulturen og magien i landet af den opgående sol, gennem et uforglemmeligt eventyr.</p>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={tokyo} alt="The streets of Tokyo" />
                    <p>Velkommen til Japan - landet, hvor tradition møder modernitet og eventyr venter om hvert hjørne. Fra de skyskrabende metropoler til de fredelige templer og det storslåede natur, byder Japan på en mangfoldighed af eventyr, der vil berige din sjæl og åbne dine øjne for en ny verden.</p>
                    <h3 className="font-bold mt-4">Udforsk det pulserende storbysliv i Tokyo:</h3>
                    <p>Træd ind i hjertet af en af verdens mest dynamiske byer, Tokyo. Her kan du fordybe dig i neonbelyste gader i Shinjuku, udforske modeens epicenter i Harajuku eller smage dig igennem autentiske japanske retter på lokale restauranter i byens små kvarterer. Tokyo pulserer med energi døgnet rundt og byder på et overflødighedshorn af oplevelser for enhver rejsendes smag.</p>
                    <h3 className="font-bold mt-4">Opdag Japans rige kulturarv i Kyoto:</h3>
                    <p>Træd tilbage i tiden i Japans kulturelle hovedstad, Kyoto. Med sine utallige templer, helligdomme og traditionelle te-huse er Kyoto et levende museum af japansk historie og tradition. Gå en tur gennem de snoede gader i Gion-kvarteret, spot geishaer på vej til deres optrædener eller oplev den storslåede skønhed i de smukt anlagte japanske haver. I Kyoto får du mulighed for at fordybe dig i den ærefrygtindgydende skønhed og roen i Japans gamle kultur.</p>
                    <h3 className="font-bold mt-4">Find ro og fordybelse på Japans naturlige vidundere:</h3>
                    <p>For de naturglade rejsende byder Japan på en overflod af naturlige vidundere. Tag på vandretur gennem de smaragdgrønne skove i Yakushima, slap af i varme kilder i de dampende onsens i Hakone eller oplev den betagende skønhed af de sneklædte tinder i de japanske alper. Uanset om du er til bjergvandring, skiløb, eller blot ønsker at genoprette forbindelsen til naturen, vil Japans naturlige landskaber fortrylle dig og efterlade dig med uforglemmelige minder.</p>
                    <h3 className="font-bold mt-4">Få indblik i Japans unikke subkulturer:</h3>
                    <p>Dyk ned i Japans farverige subkulturer og oplev en verden af manga, anime, cosplay og J-pop. Besøg Akihabara i Tokyo, kendt som elektronikkens og mangaens mekka, eller tag til Osaka for at opleve den pulserende gadekultur og den livlige atmosfære i byens Dotonbori-kvarter. Uanset om du er en dedikeret fan eller bare nysgerrig efter at udforske en anderledes side af japansk kultur, vil Japans subkulturer give dig et indblik i en verden af kreativitet og fantasi.</p>
                    <h3 className="font-bold mt-4">Klar til dit japanske eventyr?</h3>
                    <p>Uanset om du er en eventyrlysten rejsende på udkig efter nye oplevelser eller en kulturarvslivsnysgerrig sjæl på jagt efter indsigt, har Japan alt det, du søger. Lad dig fortrylle af Japans skønhed, kultur og gæstfrihed, og lad dit japanske eventyr begynde!</p>
                </div>
            </div>
        </section>
     );
};

export default Adventures;