import React, { useEffect } from "react";
import banner from "../../../images/carousel/slide_3/unikke_oplevelser.jpg";
import temple from "../../../images/carousel/slide_3/unique_exp/japan_temple.jpg";

function UniqueExp() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Oplevelser';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Kirsebærblomstring i Japan:</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">En Hyldest til Skønhed og Forfald</h2>
                    <p className="font-bold text-xl text-white max-w-[900px] drop-shadow-[2px_4px_6px_black] max-[425px]:text-lg max-[425px]:mt-4">Opdag den flygtige skønhed og dybe symbolik bag Japans ikoniske kirsebærblomster gennem årstidens foranderlige prægtighed og betagende forfald.</p>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={temple} alt="Japanese Temple" />
                    <p>Velkommen til Japan - et land rig på kulturel dybde, naturpragt og uforglemmelige oplevelser. Dyk ned i en verden af unikke eventyr, hvor hvert øjeblik åbner døren til nye opdagelser. Her er et glimt af, hvad du kan forvente at opleve:</p>
                    <h3 className="font-bold mt-4">Traditionelle Teeceremonier:</h3>
                    <p>Træd ind i den ærefrygtindgydende verden af japansk te-ceremoni, kendt som "chanoyu" eller "sado". Oplev skønheden i ceremoniens ritualer, hvor hver bevægelse og gestus har betydning. Deltag i en ceremoni for at forstå dybden af japansk æstetik og kultur.</p>
                    <h3 className="font-bold mt-4">Majestætiske Tempelbesøg:</h3>
                    <p>Japan er hjemsted for en utrolig arv af templer og helligdomme, der strækker sig fra de storslåede buddhistiske templer i Kyoto til de rolige shinto-helligdomme i de bjergområder. Udforsk de tusindårige strukturer, der bærer vidnesbyrd om landets rige åndelige arv.</p>
                    <h3 className="font-bold mt-4">Zen Meditation og Retreater:</h3>
                    <p>Find indre ro gennem zen-meditation i Japans fredfyldte klostre og zen-haver. Tag på en spirituel rejse, hvor du kan praktisere mindfulness og fordybe dig i stilheden. Oplev essensen af zen-filosofien og dens indflydelse på japansk kultur og dagligliv.</p>
                    <h3 className="font-bold mt-4">Kulinariske Eventyr:</h3>
                    <p>Dyk ned i japansk gastronomi og lad dine smagsløg udforske en verden af delikatesser. Fra de sarte smag af sushi og sashimi til de hjertevarmende retter som ramen og tempura - Japan byder på en kulinarisk rejse, der vil fortrylle dine sanser.</p>
                    <h3 className="font-bold mt-4">Traditionelle Håndværkstraditioner:</h3>
                    <p>Udforsk Japans rige traditioner inden for kunsthåndværk, som f.eks. keramik, tekstiler, og papirhåndværk. Besøg håndværkere i deres værksteder og lær om de århundredgamle teknikker, der stadig anvendes til at skabe smukke og unikke kunstværker.</p>
                    <h3 className="font-bold mt-4">Naturlige Skatte:</h3>
                    <p>Tag på opdagelse i Japans betagende natur, der spænder fra de sneklædte tinder i de japanske Alper til de frodige skove og turkisblå søer. Oplev den skiftende skønhed i Japans landskaber, der byder på alt fra sakura-blomster til majestætiske vulkaner.</p>
                    <p className="mt-4">Velkommen til en verden af unikke oplevelser i Japan. Lad dig fortrylle af landets mangfoldighed og dybde, og skab minder for livet på din rejse gennem dette fascinerende land.</p>
                </div>
            </div>
        </section>
    );
};

export default UniqueExp;