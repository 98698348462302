import React from "react";
import logo from '../logo.png';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="w-full bg-[#20222c] p-5 pt-1 flex flex-row items-center justify-center space-y-5 mt-auto">
            <img className="p-3 pb-0" src={logo} alt="logo" width="100" height="100" />
            <div className="flex flex-col">
                <p className="text-xl">Ryoko</p>
                <p className="text-sm">Rejse guider til Japan</p>
                <p className="text-sm text-gray-300">&copy; 2023 - {currentYear}</p>
                <p className="text-xs">Website by <a href="https://www.linkedin.com/in/web-tribe-40617a247/" className="underline hover:text-gray-400" target="_blank" rel="noreferrer">WEB TRIBE</a></p>
            </div>
            <div className="flex flex-col">

            </div>
        </footer>
    );
}

export default Footer;