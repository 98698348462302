import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/december/december_hq.webp";
import content from "../../../images/japan_by_month/december/content.webp";

function GuideDec() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i December';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i December</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Sølvsæsonen ankommer</h3>
                    <p>I slutningen af kalenderåret er der sæsonbestemte højdepunkter som udbredt vinterbelysning, skisæsonens begyndelse og ofte det første snefald. Vejret varierer fra efterårets kølige til ordentlig vinterkulde. Japans oshogatsu feriesæson kommer i slutningen af måneden, hvor julen er mere en symbolsk begivenhed. Skarpe, klare vinterhimmel gør bjergudsigter pop mod blå baggrunde med alt, der synes at være lidt ekstra specielt ved årets udgang.</p>
                    <h3 className="font-bold mt-4">Årstidsskiftet, årsskiftet</h3>
                    <p>Vintervejrforholdene varierer meget i hele Japan. Som du måske forventer, sydlige steder som Kyushu er varmere hele året end Hokkaido . Bjergområder begynder at få sne i det sene efterår, og termometeret kan falde under nul på koldere steder som Nagano og Niigata. Solrige dage i Tokyo kan føles varme, men Kyoto bliver overraskende koldt.</p>
                    <p>Oprindeligt en vestlig import, Julen fejres i Japan, men er meget anderledes end den familieorienterede ferie i engelsktalende lande. Dekorationer er overalt, men få gaver udveksles. Jul kalkun er uhørt, med en Yuletide tur til KFC er den foretrukne praksis. Par går ud til romantiske middage i stedet for at tilbringe tid sammen med familien. Smukke byområder i byens centrum, såsom Marunouchi-området omkring Tokyo Station, er de bedste steder at nyde smarte middage og festlige lys.</p>
                    <p>Nytårsaften og de første par dage af det nye år er den vigtigere ferie for Japansk. Mens de fleste besøgende ikke kommer til at opleve det i en familie indstilling, kan du besøge helligdomme for at se det nye år i som en lokal. Mange afholder arrangementer og tilbereder varm sake, toshikoshi soba (boghvede nudler) og svinekød suppe til besøgende.</p>
                    <h3 className="font-bold mt-4">Lyser natten op</h3>
                    <p>Som med fyrværkeri udmærker Japan sig ved at lave overdådige lysskærme om vinteren. Mange starter i midten af November og fortsætter gennem vinteren og tilføjer en vis frisson til kølige bynætter.</p>
                    <p>Du finder store skærme over hele Tokyo, og hvis du har tid, kan du endda tage Yamanote-linjen rundt i byen for at se et par af dem. En kort gåtur fra Shimbashi Station kan besøgende se Caretta Illumination. Midtbyen jul begivenhed nær Roppongi Station har en kortere løbetur, indtil 25. December.</p>
                    <p>Andre store begivenheder rundt om i Japan omfatter: Sagamiko Illumillion i Japan; Sendai-konkurrencen om stjernelys; Ashikaga - Blomsterfantasi i Tochigi; Nabana no Sato - Vinterbelysningen i Mie og Osaka Hikari-renæssancen (12/14 til 12/25). Særlig omtale går også til Kobe Luminarie, hvis imponerende udstilling fejrer Det Store Hanshin-jordskælv i 1995.</p>
                    <h3 className="font-bold mt-4">December begivenheder</h3>
                    <p>En sikker måde at slå vinterens kuldegysninger på er en tur til Ramen-messen i Osaka . Arrangementet afholdes fire dage i løbet af måneden, og der er ingen adgangsgebyr, og der er omkring 40 nudelforhandlere at vælge imellem. Over i Kobe, nankinmachi Lantern Festival i Kinabyen løber fra begyndelsen til slutningen af December med rigelige farver og smag, der trækker skarer.</p>
                    <p>En af de mere usædvanlige og karakteristiske festivaler er Namahage-begivenheden, der afholdes på nytårsaften i Oga City, Akita Prefecture . Udøvere klædt ud som namahage (en besøgende guddom, der renser uheld og bringer held) danser og glor til glæde for folkemængderne. Mere sædvanlige nytårsaften fester og fester kan findes på barer og klubber i de store byer.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideDec;