import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/august/august_hq.webp";
import content from "../../../images/japan_by_month/august/content.webp";

function GuideAug() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i August';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i August</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Festival magi på toppen af sommeren</h3>
                    <p>August tilbyder lignende sommer billetpris til Juli med strand ture, highland udflugter og vandsport alle populære tidsfordriv. Forskellen er den øgede varme og den farverige baggrund af sommerfestivaler og fyrværkeri—ikke ualmindeligt i Juli, bare meget mere udtalt i August.</p>
                    <p>Nogle af de største og bedste festivaler inkluderer Tokushima Odori og Aomoris cirka ugelange Nebuta Matsuri . Begge har dans og natbelysning i form af lyse flydere, fyrværkeri og lanterner—almindelige motiver i augustfester.</p>
                    <h3 className="font-bold mt-4">En tid med vandrende ånder</h3>
                    <p>Almindeligvis kaldet Obon på japansk, sommerferieperioden-typisk mellem August 13 og 16—er en tid, hvor afdøde familiemedlemmers ånder siges at vende tilbage til de levende og besøge deres kære igen. Traditionelt er det en tid for slægtninge at samles i deres familiehjem, nyde festlige samfundsdansbegivenheder og tage familieudflugter. Sæder på fly, tog og motorvejsbusser sælger ud på forhånd, så planlæg fremad eller overvej at bo i en af de store byer, der nyder langt færre skarer end normalt.</p>
                    <h3 className="font-bold mt-4">Tohokus tre store</h3>
                    <p>For en endelig sommerfestivaloplevelse, overvej at turnere i Tohoku-regionen i begyndelsen af August. Det er her Sandai Matsuri (tre store festivaler) i Tohoku finder sted over flere dage. De ikoniske festivalflåder i Nebuta Matsuri (8/2-7) gør denne begivenhed ganske berømt af de tre. Hver nat i fem dage lyser de menneskedrevne platforme op som gigantiske papirlygter og paraderes gennem gaderne foran enorme skarer.</p>
                    <p>Omkring samme tid kan du også deltage i Tanabata Matsuri (8/6-8) i Sendai , hvor lange farverige streamers er fastgjort til stænger og dekorere byens indkøbsarkader. I Akita har Kanto Matsuri (8/3-6) gadeparader, hvor deltagerne bærer stangmonterede lanternetårne, der giver festivalen sit navn.</p>
                    <h3 className="font-bold mt-4">Fyrværkeri lyser op om natten</h3>
                    <p>Fyrværkeri er en alvorlig forretning i Japan, og juli og August er den højeste periode for dem. Større byer som Tokyo og Yokohama er vært for højt profilerede udstillinger, der alle viser arbejdet med store pyrotekniske kunstnere over flere timer.</p>
                    <p>To mindre byer, der afholder store begivenheder, er Nagaoka i Niigata (8/2-3) og Suva i Nagano (8/15). Sidstnævnte drager fordel af en charmerende søindstilling, der inspirerede scener fra den japanske film fra 2016, dit navn, eller Kimi no Na var. Vær opmærksom på, at begge steder ser besøgende i deres hundreder af tusinder i festivaltiden, så det er en nødvendighed at foretage indkvartering og rejsearrangementer tidligt.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideAug;