import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/september/september_hq.webp";
import content from "../../../images/japan_by_month/september/content.webp";

function GuideSep() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i September';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i September</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Sommeren Letter ind i efteråret</h3>
                    <p>Selvom det nominelt er en af Japans tre efterårsmåneder, kan September stadig være ret varmt i store dele af Japan. Hokkaido er undtagelsen, hvor temperaturen afkøles, og bladene begynder at skifte farve nogle steder. Badebyer har en tendens til at tømme ud, da strandsæsonen erklæres officielt forbi, men strandture er stadig en levedygtig mulighed med den ekstra bonus på færre skarer.</p>
                    <p>Med sommerferien over og folk vender tilbage til arbejde og skole, det er ikke kun strandene, der ser færre skarer, men meget af Japan generelt. Bemærk, at vejret stort set er meget godt, men kan være uforudsigeligt med tyfoner på deres hyppigste fra slutningen af August og gennem store dele af September. Prøv at efterlade en vis fleksibilitet i din rejseplan, hvis en tyfon tilfældigvis passerer igennem, mens du besøger.</p>
                    <h3 className="font-bold mt-4">National sport-traditionel og moderne</h3>
                    <p>Siden baseball blev introduceret i landet i 1872, har det været en fast del af japansk sport. Hvert år konkurrerer 12 hold om liga-vimpler og pladser i slutspillet, der afslutter sæsonen. Med farverigt offbeat navne som karper og svaler, kan Japanske klubber også prale af nogle af de mest lidenskabelige (men høflige) sportsfans. Hvis noget, er støttekulturen lige så interessant som selve spillene.</p>
                    <p>Billetter til baseballkampe kan ofte købes på forhånd i dagligvarebutikker, og det er værd at tjekke for tilgængelighed selv med kort varsel. Hvis du ikke er en fan af baseball, overveje et besøg på Susuka kredsløb i Mie eller tvilling Ring Motegi i Tochigi . Begge tilbyder et udvalg af motorsportsbegivenheder i September.</p>
                    <p>For en mere traditionelt japansk sport, sigter mod at være i Tokyo fra den anden til fjerde uge af September. September Grand Sumo turneringen finder sted på Ryogoku Kokugikan sumo stadium. Billetter kan købes op til en måned i forvejen. Læs mere om, hvordan du reserverer dine billetter her .</p>
                    <h3 className="font-bold mt-4">Festival højdepunkter omkring Japan</h3>
                    <p>Selvom det ikke er på niveau med August, September har en fin matsuri sæson af sin egen. Festivalen finder sted i Toyama byforstaden YatsuoMachi de første tre dage af September. Med hundreder, der danser i procession gennem gader foret med gamle træbygninger, er det Toyama Prefecture ' s store årlige festival.</p>
                    <p>En af Japans mest dramatiske udstillinger i Osaka, den japanske Danjiri Festival i Osaka, spænder tilskuerne med sin udstilling af bærbare helligdomme, der kører i halsbrækkende tempo gennem fyldte gader. Også i midten af September, Tsurugaoka Hachimangu Reitaisai har Yabusame-Shinji, en begivenhed, der kombinerer traditionel hest bueskytte med højtidelige ritualer på en historisk helligdom. Senere på måneden holder Aisu Matsuri i Fukushima sin Aisu Matsuri, hvor farverige processioner bringer forskellige perioder af lokalhistorie til live.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideSep;