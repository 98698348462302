import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/march/march_hq.webp";
import content from "../../../images/japan_by_month/march/content.webp";

function GuideMarts() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i Marts';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i Marts</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Nye begyndelser i det tidlige forår</h3>
                    <p>Med toppen af vinteren forbi, scenen er sat til nye begyndelser, når Japan Letter ind i foråret. Blommeblomster blomstrer fortsat i dele af det centrale Japan og længere mod nord. Den farverige Hinamatsuri girls ' festival afholdes landsdækkende den 3.Marts, og kirsebærblomster begynder at blomstre i Tokyo og andre varmere steder for at indvarsle starten på foråret.</p>
                    <p>Selvom vejret i Marts er betydeligt varmere og mere forårlignende end Februar, kan temperaturerne pludselig falde. På de fleste feriesteder er det stadig muligt at stå på ski og gå på ski, og de langsomme udflugter med varme kilder er stadig en populær måde at tilbringe nogle af de koldeste dage på.</p>
                    <h3 className="font-bold mt-4">Forårs sne</h3>
                    <p>Vintersportsæsonen er stadig meget levende på nogle japanske skiområder. Store resorts som Niseko og Shiga Kogen forbliver faktisk åbne helt op til den første uge i Maj, omend med faldende afkast i snekvalitet.</p>
                    <p>Spring skiing benefits from warmer temperatures and relatively empty slopes compared with winter. Sudden snowfalls do sometimes occur at higher altitudes, though rain is a more likely occurrence. Check weather reports in your area to see if a ski excursion is a viable option on the day.</p>
                    <h3 className="font-bold mt-4">Ild, lys, vitalitet</h3>
                    <p>Lys og farve figurerer fremtrædende i Marts fejringer, med natlige belysninger er et fælles træk ved festivaler i Kansai. Kyoto Higashiyama-distriktet er vært for Kyoto Higashiyama Hanatouro, hvor tusindvis af lanterner lyser op på en rute, der strækker sig fra Shoren-in-templet til Kiyomisudera-templet . I løbet af denne tid forlænger forskellige templer langs ruten deres timer for at iscenesætte overdådige belysninger for besøgende.</p>
                    <p>Nigatsu-do, buddhistisk tempel i Todaiji i Nara er stedet for den årlige Shunikai (også kendt som Omisutori eller omatsumo), som tager flere måneder at forberede. De store fakler, der bruges til at belyse vejen for processionen af præster, der udfører denne tjeneste fra 1.til 14. Marts, er kendt som omatsumo, men den 12. Marts, dagen for omisutori, når processionen af præster udfører ritualet med at trække vand fra en hellig brønd, bæres enorme fakler, der spreder lyse gnister af ild fra scenen ind i mørket, hvilket gør det til den største forestilling i ceremonieserien.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideMarts;