import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/november/november_hq.webp";
import content from "../../../images/japan_by_month/november/content.webp";

function GuideNov() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i November';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i November</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Strålende blå himmel og levende farver</h3>
                    <p>For det meste af Japan bringer November frisk luft, klar himmel og strålende efterårsløv, hvilket gør det til en behagelig måned at rejse. Vejret bliver gradvist køligt, især om morgenen og aftenen, men en varm forårstur er et simpelt middel til eventuelle kolde trylleformularer, du måtte støde på. Hold øje med julelys og belysningsbegivenheder-hvoraf mange også begynder i November.</p>
                    <h3 className="font-bold mt-4">Efterårsfarver omkring Japan</h3>
                    <p>De japanske ord momiji og koyo henviser til røde og gule blade, stjerneattraktionen i efterårssæsonen. Bjerg-og søområder er populære destinationer, men du kan nyde farverne praktisk talt overalt. De fleste helligdomme og templer har attraktive grunde med deres rimelige andel af ahorntræer.</p>
                    <h3 className="font-bold mt-4">I og omkring Tokyo</h3>
                    <p>Med et stort antal ginko-træer, der viser deres farver fra midten af November til begyndelsen af December, er Meiji Jingu Gaien et berømt sted for efterårsbladvisning. Andre steder, der er nemme at nå i centrum af Tokyo, er Korakuen Gardens nær Tokyo Dome og Rikugien Gardens.</p>
                    <p>En nem dagstur fra Tokyo, Nikko er hjemsted for Nikko Toshogu Shrine —en af Japans mest berømte og arkitektonisk imponerende helligdomme. Bladene i grunden er en yderligere grund til at besøge, hvis du har brug for en. En anden populær sidetur fra Tokyo er tempelbyen Kamakura ved havet . Hvis der efter skumringen mod slutningen af måneden, skal du gå til Hasedera-templet for at se de røde Ahornblade oplyst.</p>
                    <h3 className="font-bold mt-4">Kyoto og andre steder</h3>
                    <p>Efterårsbladene kommer sent til Kyoto, og sæsonen begynder i midten af November. Vælg mellem templer, helligdomme, tidligere paladser og naturskønne flodbredder for at nyde bladene i den gamle hovedstad. For at se bladene efter mørkets frembrud, besøg Kiyomisu-dera-templet for den lysende begivenhed, der løber fra midten til slutningen af November.</p>
                    <p>Hvis du planlægger dit besøg i begyndelsen af November, kan du overveje at besøge Koyasan. Den populære bjergtop tempelby får sin andel af efterårsfarve foran Kyoto.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideNov;