import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/events/events_hq.webp";
import content from "../../../images/japan_by_interests/events/content.webp";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Festivaller og Events i Japan';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Festivaller og Events i Japan</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Se fyrværkeri, nyd japansk gadekøkken og deltag i traditioner</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Festivaler, kendt på japansk som matsuri, er dybt forankret i Japans kultur og spænder fra de små og fredelige til de store og intense</h3>
                    <p>Traditionelle japanske festivaler fejres ofte omkring helligdomme og templer, hvor deltagerne er klædt i farverige klæder og ofte bærer tunge bærbare helligdomme på deres skuldre.</p>
                    <h3 className="font-bold mt-4">Sommer</h3>
                    <p>Sommeren er sæsonen for fyrværkeri, kendt som hanabi i Japan, der lyser himlen op med spektakulære, farverige udstillinger. For et kig ind i det traditionelle Japan må du ikke gå glip af Gion Festivalen i Kyoto, Aomori Nebuta Festivalen i Aomori eller de hundredvis af sommerfestivaler, der finder sted i juni, juli og august rundt omkring i Japan.</p>
                    <h3 className="font-bold mt-4">Vinter</h3>
                    <p>Om vinteren er der masser af snemandfestivaler, hvor animekarakterer, historiske og politiske figurer samt berømte arkitektoniske vidundere skæres ud i sne og is, mens lanterner lyser stier foret med igloer op. Sapporo Snow Festivalen og Nozawa Fire Festivalen tiltrækker tusindvis af mennesker hvert år.</p>
                    <h3 className="font-bold mt-4">Forår</h3>
                    <p>Foråret bringer blomsterfestivaler på højdepunktet af kirsebærblomstsæsonen og mens andre typer blomster blomstrer. Nyd maden, drikken og den venlige atmosfære under træer, i parker, omkring slotte og langs floder. Hirosaki Cherry Blossom Festivalen og Kakunodate Cherry Blossom Festivalen er blot et par af mange at overveje.</p>
                    <h3 className="font-bold mt-4">Efterår</h3>
                    <p>Om efteråret bringer de røde japanske lønblade kaldet momiji folk udendørs. Efterårsfestivaler, der afholdes omkring templer og helligdomme, som f.eks. Fujiwara Autumn Festivalen i Hiraizumi og Kawagoe Festivalen, fremhæver områdernes historie med historiske parader, noh-optrædener og sæsonbetonet mad. Efteråret er også en sæson, hvor internationale begivenheder som Tokyo International Film Festival, Tokyo Motor Show (hvert andet år) og Tokyo Comic Con afholdes.</p>
                    <h3 className="font-bold mt-4">Noget anderledes</h3>
                    <p>En tur til den skæve Festival of the Steel Phallus i Kanagawa Prefecture vil vise dig, at størrelsen betyder noget - i det mindste på denne festival tidligt i april. Skru op for varmen ved at deltage i en af de Fire Festivals, herunder den flammende Nachi Fire Festival den 14. juli i Wakayama Prefecture, der kulminerer i tolv bærbare helligdomme formet som Nachi Falls, der bliver antændt. Sen forår er rammen om blomsterfestivaler, f.eks. når babyblå Nemophila dækker markerne i Hitachi Seaside Park i Ibaraki Prefecture.</p>
                    <p>Musikfestivaler er almindelige om sommeren, hvor hovednavnet er Fuji Rock Festivalen i Niigata Prefecture.</p>
                    <p>Arrangementer, der viser regional mad, kan ofte findes i Yoyogi Park i Tokyo, og landets konferencecentre og fora er værter for alle slags begivenheder lige fra turismefora til præsentationer af den nyeste teknologi.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;