import React, { useEffect } from "react";
import banner from "../../../images/japan_by_month/july/july_hq.webp";
import content from "../../../images/japan_by_month/july/content.webp";

function GuideJuli() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japan i Juli';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japan i Juli</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Regnen aftager og varmen tager til</h3>
                    <p>I begyndelsen af juli er regntiden enten slut eller er ved at falde. Temperatur og fugtighed stiger støt, og sommeren kommer i fuld gang. Populære tidsfordriv omfatter bjergvandringer, strandture, baseball-og ølhaver. Sørg for at medtage et udvalg af let tøj, når du pakker, og spar ikke solcreme.</p>
                    <h3 className="font-bold mt-4">Strande og badning</h3>
                    <p>Mens vi har utroligt smukke strande, kan varmen og fugtigheden i Juli være en deal breaker for nogle. Du finder populære strande i Chiba, Fukuoka og andre kystområder som Kamakura har dem naturligvis også. Næsten alle strande er åbne for svømmesæsonen i Juli.</p>
                    <p>Selvom ukendt for de fleste internationale besøgende, Japans blomstrende vand og spa Forlystelsespark industri er værd at tjekke ud. Nagashima Resort i Mie kombinerer svømmebassin, onsen og forlystelsesparker i et massivt kompleks for en hel dag med vandlevende underholdning. Andre parker som Spa verden i Osaka og Hakone Yunessun i Hakone har svømmebassiner og badefaciliteter med tema.</p>
                    <h3 className="font-bold mt-4">Øhopping</h3>
                    <p>En ø nation, Japan har uendelige ø-hopping muligheder. Besøg art islands i Seto Inland Sea, pragtfulde koralrev og ubeboede øer og Jurassic Park-agtige vulkanske øer syd for Tokyo. Der er færgeforbindelser til, fra og mellem øerne på deres højdepunkt i juli og August.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJuli;