import React, { useEffect, useState } from "react";
import banner from "../images/expirence_tab/banner.webp"
import tokyo from "../images/expirence_tab/tokyo.jpg";
import kyoto from "../images/expirence_tab/kyoto.jpg";
import osaka from "../images/expirence_tab/osaka.jpg";
import hiroshima from "../images/expirence_tab/hiroshima.jpg";
import nara from "../images/expirence_tab/nara.jpg";
import hokkaido from "../images/expirence_tab/hokkaido.jpg";
import okinawa from "../images/expirence_tab/okinawa.jpg";
import fuji from "../images/expirence_tab/fuji.jpg";
import gardens from "../images/expirence_tab/gardens.jpg";
import onsen from "../images/expirence_tab/onsen.jpg";
import history from "../images/expirence_tab/history.jpg";
import cultural from "../images/expirence_tab/culturel.jpg";

function Oplevelser() {
    const [selectedDestination, setSelectedDestination] = useState(null);

    const interests = [
        { 
            name: "Tokyo",
            title: "Tokyo Tower:",
            info: "Tokyo Tower, en ikonisk landemærke i Japans hovedstad, stiger majestætisk over byens skyline og tilbyder en betagende udsigt over det pulserende byliv. Med sine 333 meter er tårnet et af verdens højeste selvstændige stålkonstruktioner og et must-see for enhver besøgende i Tokyo. Du kan tage elevatoren op til det observatorium og nyde panoramaudsigten over byen, der strækker sig helt til fjerne bjerge på klare dage. Desuden lyser tårnet op om natten og skaber en fortryllende baggrund for aftenvandringer gennem byens gader.",
            title2: "Shibuya Crossing:",
            info2: "Shibuya Crossing er måske en af verdens mest berømte fodgængerovergange og symboliserer Tokyos energiske tempo og mangfoldighed. Med tusindvis af mennesker, der krydser veje på tværs af hinanden i et synkront dans, er dette sted en fascinerende oplevelse i sig selv. Det pulserende kryds er omgivet af neonreklamer og skyskrabere, der afspejler Japans moderne kultur. Det er det perfekte sted at observere byens livlige energi og snappe nogle uforglemmelige billeder.",
            title3: "Meiji Shrine:",
            info3: "Meiji Shrine, beliggende i hjertet af Shibuya-ku, er en helligdom dedikeret til kejser Meiji og kejserinde Shoken, der regerede Japan i slutningen af det 19. og begyndelsen af det 20. århundrede. Dette hellige sted tilbyder en tilbagetrukket oase af ro midt i den travle by. Besøgende kan passere gennem de store torii-porte, der fører til helligdommens kompleks og opleve den fredfyldte atmosfære. Du kan også være heldig at deltage i en traditionel japansk bryllupsceremoni, som ofte finder sted her og give dig et unikt indblik i landets kulturarv.",
            title4: "Shinjuku Gyoen National Garden:",
            info4: "Shinjuku Gyoen National Garden er en smuk kontrast til Tokyos hektiske tempo og et ideelt sted for en afslappende tilbagetrukket dag i naturen. Denne velplejede have kombinerer traditionelle japanske landskabsdesignelementer med vestlige haver og byder på et varieret udvalg af flora og fauna året rundt. Du kan tage en fredelig spadseretur langs de snoede stier, beundre de farverige blomsterbede eller slappe af under skyggen af træerne. Uanset årstiden vil Shinjuku Gyoen fortrylle dig med sin skønhed og ro.",
            title5: "Tsukiji Fish Market (nu Toyosu Market):",
            info5: "Tsukiji Fish Market var engang verdens største fiske- og skaldyrsauktion og et mekka for sushielskere over hele verden. Selvom markedet nu er flyttet til Toyosu-området, er det stadig et spændende sted at besøge for at opleve Japans rige kulinariske kultur. Her kan du udforske de farverige markedsboder, se fiskere arbejde med deres friske fangst og nyde en lækker skål med sushi lavet af de fineste råvarer. En tur til Toyosu Market er en uforglemmelig rejse gennem japansk madhistorie og en fantastisk mulighed for at smage nogle af verdens bedste sushi.",
            title6: "Tokyo Disneyland and DisneySea:",
            info6: "Tokyo Disneyland og DisneySea er to magiske forlystelsesparker beliggende i Urayasu, Chiba Prefecture, og tilbyder en verden af eventyr og underholdning for besøgende i alle aldre. Inspireret af Disneys ikoniske film og figurer, er disse parker hjemsted for en række spændende forlystelser, farverige parader og spektakulære shows. Uanset om du er fan af Mickey Mouse, Star Wars eller Marvel-superhelte, vil du finde noget at glæde dig over her. Tokyo Disneyland og DisneySea er det perfekte sted at slippe fantasien løs og skabe uforglemmelige minder med familie og venner.",
            imageUrl: tokyo,
        },
        { 
            name: "Kyoto",
            title: "Kinkaku-ji (Golden Pavilion):",
            info: "Kinkaku-ji, også kendt som Det Gyldne Tempel, er en af Kyotos mest ikoniske og betagende attraktioner. Beliggende ved bredden af ​​et smukt spejlklart vand i Rokuon-ji tempelkomplekset, skinner templet i sollyset med sine gyldne refleksioner og skaber et fantastisk syn. Templet, der er bygget i zen-buddhistisk stil, er omgivet af fredelige haver og smukke landskaber, der inviterer besøgende til at reflektere og finde ro. En gåtur omkring Kinkaku-ji er en uforglemmelig oplevelse, der efterlader et varigt indtryk af skønhed og åndelighed.",
            title2: "Fushimi Inari-taisha Shrine:",
            info2: "Fushimi Inari-taisha Shrine er en af Japans mest berømte og fascinerende helligdomme, dedikeret til Inari, gudinden for ris, velstand og forretning. Det mest iøjnefaldende træk ved dette hellige sted er de tusindvis af røde torii-porte, der danner en fortryllende tunnel gennem skoven op ad bjerget. En vandring langs de snoede stier, der fører til toppen af bjerget, giver besøgende mulighed for at nyde den smukke natur og den åndelige atmosfære. Udsigten fra toppen af bjerget er spektakulær og belønner dem, der når frem med en enestående panoramaudsigt over Kyoto.",
            title3: "Arashiyama Bamboo Grove:",
            info3: "Arashiyama Bamboo Grove er en naturskøn perle beliggende i udkanten af Kyoto og tilbyder en fortryllende oplevelse for besøgende. Den tætte bambusskov, der strækker sig så langt øjet kan se, skaber en atmosfære af mystik og ro. En spadseretur gennem bambusskoven fører dig ind i en anden verden, hvor solens stråler filtreres gennem de høje bambustræer og kaster et magisk lys over stien. Dette sted er ideelt til at nyde naturens skønhed og finde indre fred.",
            title4: "Kiyomizu-dera Temple:",
            info4: "Kiyomizu-dera Temple er et af Japans mest ikoniske og ældste templer og er kendt for sin spektakulære arkitektur og betagende udsigt over Kyoto. Beliggende på en bakketop med udsigt over byen, tilbyder templet en fantastisk udsigt, især om foråret, når kirsebærblomsterne blomstrer, og om efteråret, når løvet skifter farve. Templet er også kendt for sin imponerende hovedhal, der er bygget uden brug af søm, og for den hellige vandfaldsbrønd, hvor besøgende kan drikke vandet for lykke og velstand.",
            title5: "Gion District (for traditional culture and geisha):",
            info5: "Gion District er et af Kyotos mest historiske og traditionelle kvarterer og er kendt for sine smukke gamle gader, traditionelle te-huse og elegante geishaer. En gåtur gennem Gion er som at træde tilbage i tiden til det gamle Japan, hvor du kan støde på geishaer iført deres smukke kimonoer på vej til en optræden eller et møde. Om aftenen kommer kvarteret virkelig til live med lysende lanterner og stemningsfulde te-huse, der tilbyder en autentisk smag af japansk kultur og gæstfrihed.",
            imageUrl: kyoto,
        },
        { 
            name: "Osaka",
            title: "Osaka Castle:",
            info: "Osaka Castle, med sin storslåede arkitektur og rige historie, står som et symbol på Osakas stolte fortid og kulturelle arv. Bygget i det 16. århundrede af den mægtige samurai Toyotomi Hideyoshi, tjente slottet som et imponerende militært fæstning og politisk center. I dag tiltrækker Osaka Castle besøgende fra hele verden med sine majestætiske tårne, imponerende voldgrave og smukke omgivelser. Inde i slottet finder du et museum, der udforsker Japans samuraihistorie og Hideyoshis liv og arv. Fra toppen af slottet kan du nyde en fantastisk udsigt over Osaka skyline, der strækker sig så langt øjet kan se.",
            title2: "Dotonbori District:",
            info2: "Dotonbori District er hjertet af Osakas livlige natteliv og madscene og et must-see for enhver besøgende i byen. Med sine skrigende neonskilte, travle gader og duftende madboder er dette kvarter en fest for sanserne. Tag en tur langs Dotonbori-floden og oplev de farverige lys, der spejler sig i vandet, eller tag en selfie med Glico Running Man, et ikonisk symbol på byen. Om natten kommer Dotonbori virkelig til live, når restauranter, barer og underholdningssteder åbner deres døre og inviterer dig til at opleve Osakas pulserende atmosfære.",
            title3: "Universal Studios Japan:",
            info3: "Universal Studios Japan er en af Asiens mest populære forlystelsesparker og tilbyder en verden af spænding og underholdning for hele familien. Inspireret af nogle af Hollywoods største blockbuster-film og tegneseriefigurer, er parken hjemsted for en række spændende forlystelser, shows og attraktioner. Uanset om du er fan af Harry Potter, Jurassic Park eller Minions, vil du finde noget at begejstre dig her. Gå ikke glip af Wizarding World of Harry Potter, hvor du kan udforske Hogwarts Castle, drikke smørøl og køre på en magisk rutsjebane gennem Hogsmeade landsbyen.",
            title4: "Osaka Aquarium Kaiyukan:",
            info4: "Osaka Aquarium Kaiyukan er en af verdens største og mest imponerende akvarier og et vidunderligt sted at udforske havets dybder og mangfoldighed. Med mere end 30.000 dyr og 620 arter, inklusive hajer, rokker og delfiner, tilbyder akvariet en unik mulighed for at komme tæt på marine liv og lære om deres naturlige habitater. Den mest imponerende attraktion er den gigantiske tank, der indeholder en rekordstor hvalskelet og en bred vifte af havdyr, der svømmer frit rundt. En tur til Osaka Aquarium Kaiyukan er både sjov og uddannelsesmæssig og er perfekt til både børn og voksne.",
            title5: "Shitennoji Temple:",
            info5: "Shitennoji Temple, en af de ældste buddhistiske templer i Japan, har en historie, der strækker sig mere end 1.400 år tilbage i tiden og er et vigtigt kulturelt og religiøst centrum i Osaka. Templet, der blev grundlagt af prins Shotoku, en af Japans mest betydningsfulde historiske figurer, er dedikeret til fire himmelske konger og er en helligdom for buddhister over hele landet. Mens du udforsker komplekset, vil du opdage en række smukke pagoder, hellige haller og buddhistiske statuer, der afspejler templet rige arv og betydning. En tur til Shitennoji Temple er en unik mulighed for at fordybe sig i Japans religiøse og kulturelle arv og finde fred og ro midt i byens travlhed.",
            imageUrl: osaka,
        },
        { 
            name: "Hiroshima",
            title: "Hiroshima Peace Memorial Park og Museum:",
            info: "Hiroshima Peace Memorial Park og Museum er et rørende og betydningsfuldt sted, der minder os om de forfærdelige konsekvenser af atomvåben og hylder fred og håb for fremtiden. Parken, der ligger på det sted, hvor atombomben blev detoneret den 6. august 1945, er fyldt med smukke mindesmærker, fredssøjler og en bevægende samling af genstande, der fortæller historien om Hiroshimas ødelæggelse og genopbygning. Museet giver et dybtgående indblik i byens tragiske historie og menneskelig lidelse og formår samtidig at inspirere besøgende til at forfølge fred og forsoning. En tur til Hiroshima Peace Memorial Park og Museum er en stærk og tankevækkende oplevelse, der efterlader et varigt indtryk.",
            title2: "Miyajima Island (med den berømte Itsukushima Shrine og flydende torii port):",
            info2: "Miyajima Island, også kendt som Itsukushima, er en af Japans mest ikoniske og maleriske destinationer og er berømt for sin smukke natur og kulturelle betydning. Øen er hjemsted for det storslåede Itsukushima Shrine, der er bygget på pæle over vandet og er mest kendt for sin flydende torii-port, der synes at svæve ved højvande. Shrine-komplekset, der er dedikeret til gudinden af ​​havet, tilbyder en betagende blanding af arkitektur og naturskønhed og er et helligt sted for pilgrimsrejser og meditation. Udover at udforske templet kan besøgende nyde en række aktiviteter på øen, herunder vandreture, smagning af lokale delikatesser og interaktion med vilde hjorte, der vandrer frit rundt. En dag på Miyajima Island er en eventyrlig rejse gennem Japans kultur og natur, der vil efterlade dig forundret.",
            imageUrl: hiroshima,
        },
        { 
            name: "Nara",
            title: "Todai-ji Temple (huser den store Buddha):",
            info: "Todai-ji Temple er en imponerende buddhistisk tempelkompleks og et af Nara's mest berømte vartegn. Templet er hjemsted for Daibutsuden, en af verdens største træbygninger, der huser en enorm statue af den store Buddha, kendt som Daibutsu. Denne statue, støbt i bronze, er en bemærkelsesværdig teknisk bedrift og en hyldest til Japans religiøse og kulturelle arv. En gåtur gennem Daibutsuden er en overvældende oplevelse, der giver besøgende mulighed for at beundre den kolossale skala og ærefrygtindgydende skønhed af denne hellige skulptur.",
            title2: "Nara Park (hjem til venlige hjorte):",
            info2: "Nara Park er en frodig og indbydende park beliggende i hjertet af Nara by og er kendt for sin venlige hjortebestand, der strejfer frit omkring. Disse hjorte, kendt som shika, betragtes som hellige væsener i japansk tradition og er et symbol på fred og velstand. Besøgende har mulighed for at komme tæt på disse majestætiske dyr, fodre dem og endda tage billeder med dem. Ud over de venlige hjorte tilbyder parken også fredelige stier, smukke springvand og historiske monumenter, der gør det til et perfekt sted for en afslappet picnic eller en eftermiddagsspads.",
            title3: "Kasuga Taisha Shrine:",
            info3: "Kasuga Taisha Shrine er en af Nara's ældste og mest betydningsfulde shinto-helligdomme og er kendt for sine hundreder af hængende lanterner, der skaber en magisk atmosfære om natten. Helligdommen, grundlagt i det 8. århundrede, er dedikeret til gudinden for fred og velstand og er et populært pilgrimssted for besøgende fra nær og fjern. Den hellige sti, der fører op til helligdommen, er foret med disse traditionelle lanterner og skaber en atmosfære af mystik og ærefrygt. En tur til Kasuga Taisha Shrine er en uforglemmelig oplevelse, der forbinder dig med Japans rige åndelige arv og giver dig mulighed for at beundre den enestående skønhed i dette hellige sted.",
            imageUrl: nara,
        },
        { 
            name: "Hokkaido",
            title: "Sapporo Snow Festival (om vinteren):",
            info: "Sapporo Snemassefestivalen, der finder sted om vinteren, er en af Hokkaidos mest spektakulære begivenheder og tiltrækker tusindvis af besøgende fra hele verden hvert år. Festivalen er berømt for sine imponerende sneskulpturer, der spænder fra detaljerede kunstværker til kæmpestore strukturer, der lyser op om natten. Besøgende kan udforske de snedækkede gader i Odori Park, hvor skulpturerne er udstillet, og deltage i forskellige aktiviteter som sneskulpturkonkurrencer og sneslædekørsel. Sapporo Snow Festival er en unik mulighed for at opleve magien ved vinteren i Hokkaido og fejre kreativiteten hos japanske kunstnere.",
            title2: "Shikotsu-Toya National Park:",
            info2: "Shikotsu-Toya National Park er et af Japans smukkeste naturområder og et paradis for naturelskere og eventyrlystne sjæle. Parken, der omfatter de to kratersøer, Lake Shikotsu og Lake Toya, byder på en imponerende naturskønhed med krystalklart vand, omgivet af bjerge og frodig vegetation. Besøgende kan udforske parken på forskellige vandrestier, nyde en bådtur på søerne eller slappe af ved bredden og beundre udsigten. Om vinteren tilbyder parken også muligheder for skiløb, snesko vandring og andre vintersportsgrene.",
            title3: "Asahiyama Zoo:",
            info3: "Asahiyama Zoo er en af Hokkaidos mest populære turistattraktioner og et must-see for dyreelskere i området. Zoo'en, der ligger i byen Asahikawa, er kendt for sine innovative og interaktive udstillinger, der giver besøgende en unik mulighed for at komme tæt på dyrene og lære om deres naturlige adfærd og levesteder. Nogle af de mest populære udstillinger inkluderer pingviner, isbjørne, røde pandaer og sneleoparder. Zoo'en er også kendt for sit berømte 'Penguin Walk', hvor pingvinerne får lov til at gå rundt i parken og interagere med besøgende.",
            title4: "Noboribetsu Onsen (varme kilder):",
            info4: "Noboribetsu Onsen er en af Japans mest berømte varme kilder destinationer og tilbyder en afslappende og helbredende oplevelse for besøgende. Beliggende i det maleriske Noboribetsu-højland i det sydlige Hokkaido, er dette område hjemsted for en række varme kilder og svovlholdige kilder, der siges at have terapeutiske egenskaber. Besøgende kan nyde en dukkert i de varme kilder, slappe af i japanske ryokan (traditionelle kroer) og forkæle sig med spa-behandlinger. Området omkring Noboribetsu Onsen byder også på smukke naturlandskaber, herunder vulkanske dale, vandfald og naturskønne vandreture.",
            imageUrl: hokkaido,
        },
        { 
            name: "Okinawa",
            title: "Okinawa Churaumi Aquarium:",
            info: "Okinawa Churaumi Akvarium er et af verdens mest imponerende akvarier og tilbyder en fascinerende rejse gennem havets dybder. Med et bredt udvalg af marine liv, herunder majestætiske hvalhajer, farverige tropiske fisk og smukke koralrev, er dette akvarium et must-see for enhver dyreelsker og eventyrlysten rejsende. Et af højdepunkterne er det enorme hvalhaj-akvarium, der giver besøgende mulighed for at se disse majestætiske skabninger svømme fredeligt forbi. Uanset om du er en erfaren dykker eller bare nysgerrig på havets vidundere, vil Okinawa Churaumi Aquarium forbløffe og fortrylle dig.",
            title2: "Shuri Castle:",
            info2: "Shuri Castle, også kendt som Shurijo, er et ikonisk symbol på Okinawas rige historie og kultur. Dette storslåede slot, der engang var sædet for kongeriget Ryukyu, er en arkitektonisk perle med sin røde teglsten, elegante pagoder og imponerende porte. En tur gennem slottets smukt bevarede haver og arkader giver besøgende mulighed for at dykke ned i Okinawas fascinerende fortid og beundre slottets pragt og storhed. Desværre blev slottet ødelagt under Anden Verdenskrig, men det er blevet restaureret til sin tidligere herlighed og er nu et UNESCO-verdensarvssted.",
            title3: "Ishigaki Island og Taketomi Island for strande og snorkling:",
            info3: "Ishigaki Island og Taketomi Island er to af Okinawas mest naturskønne og paradisiske øer, der tilbyder besøgende en sand oase af skønhed og afslapning. Med deres krystalklare farvande, bløde hvide sandstrande og frodige tropiske landskaber er disse øer det perfekte sted at nyde solen, havet og strandlivet. Du kan snorkle blandt farverige koralrev, svømme med tropiske fisk eller bare slappe af på stranden og lade bølgerne vaske dine bekymringer væk. En tur til Ishigaki Island og Taketomi Island er en uforglemmelig rejse til paradis og en mulighed for at genoplive din ånd og forynge din sjæl.",
            imageUrl: okinawa,
        },
        { 
            name: "Mount Fuji",
            title: "Bestigning af Mount Fuji (i klatresæsonen):",
            info: "At bestige Mount Fuji er en uforglemmelig oplevelse, der tiltrækker eventyrlystne sjæle fra hele verden. I løbet af klatresæsonen, der normalt strækker sig fra juli til august, stiger tusindvis af mennesker op ad bjergets skråninger i et forsøg på at nå toppen og opleve solopgangen over Japans højeste bjerg. Selvom bestigningen kan være udfordrende, belønnes besøgende med en spektakulær udsigt over det omkringliggende landskab og en følelse af præstation, når de når toppen. Det er vigtigt at være godt forberedt og have den rette udstyr samt respektere bjergets skrøbelige økosystem under ens opstigning.",
            title2: "Fuji Five Lakes region for scenic views:",
            info2: "Fuji Five Lakes-regionen, der ligger ved foden af Mount Fuji, er berømt for sine betagende landskaber og spektakulære udsigter over bjergene. De fem søer - Kawaguchi, Yamanaka, Sai, Shoji og Motosu - danner en malerisk baggrund for bjergens majestætiske skikkelse og tilbyder besøgende mulighed for at nyde naturen i sit fineste. Området byder på en række aktiviteter, herunder bådture på søen, vandreture langs stierne og afslappende stunder ved bredden. Uanset om du er på udkig efter eventyr eller blot ønsker at slappe af og nyde naturens skønhed, er Fuji Five Lakes-regionen et must-see rejsemål i Japan.",
            imageUrl: fuji,
        },
        { 
            name: "Japanske haver",
            title: "Kenrokuen Have i Kanazawa:",
            info: "Kenrokuen Have, beliggende i Kanazawa, er en af Japans mest betydningsfulde og smukkeste traditionelle haver. Navnet Kenrokuen betyder 'have med de seks karakteristika', der refererer til de seks aspekter af skønhed, som en perfekt have bør have ifølge kinesisk litteratur. Haven tilbyder en harmonisk blanding af vandfunktioner, stenede landskaber, velplejede træer og farverige blomsterbede, der skaber en fortryllende atmosfære. En gåtur gennem Kenrokuen Have er som en rejse gennem århundreder af japansk havedesign og æstetik og er en oplevelse, der fortryller alle sanser.",
            title2: "Ritsurin Have i Takamatsu:",
            info2: "Ritsurin Have, beliggende i Takamatsu, er en af Japans fineste eksempler på en traditionel japansk landskabshave. Denne smukt anlagte have strækker sig over 75 hektar og byder på en række naturskønne udsigter, herunder søer, vandfald, broer og veltrimmede træer og buske. Hver sæson bringer sin egen skønhed til haven, fra kirsebærblomsterne om foråret til de farverige løv om efteråret. Besøgende kan nyde en fredelig spadseretur langs de snoede stier, beundre de gamle te-huse og opleve roen og harmonien i denne enestående have.",
            title3: "Adachi Kunstmuseumshave i Shimane:",
            info3: "Adachi Kunstmuseumshave, beliggende i Shimane, er ikke kun en af Japans smukkeste haver, men også hjemsted for en imponerende samling af moderne japansk kunst. Denne unikke have er designet som en levende maleri, hvor hvert element er omhyggeligt arrangeret for at skabe en harmonisk og æstetisk tiltalende helhed. Besøgende kan nyde synet af azaleaer, rhododendroner, iris og andre blomstrende planter, der skaber en farverig kulisse til kunstværkerne. Uanset om du er en haveentusiast eller en kunstelsker, vil Adachi Kunstmuseumshave fortrylle dig med sin skønhed og kreativitet.",
            imageUrl: gardens,
        },
        { 
            name: "De traditionelle onsen-byer",
            title: "Hakone:",
            info: "Hakone, beliggende ved foden af Mount Fuji, er en smuk onsen-by kendt for sine varme kilder, naturskønne landskaber og afslappende atmosfære. Dette charmerende sted tilbyder besøgende muligheden for at nyde en traditionel japansk badeoplevelse omgivet af frodige skove, snoede floder og smukke bjergtoppe. Ud over at slappe af i de varme kilder kan besøgende udforske Hakones mange seværdigheder, herunder Hakone Shrine, Hakone Open-Air Museum og det berømte Hakone-piratskibskrydstogt på Ashi-søen.",
            title2: "Beppu:",
            info2: "Beppu, også kendt som Japans onsen-hovedstad, er berømt for sit imponerende antal varme kilder og unikke onsen-oplevelser. Byen er hjemsted for mere end 2.000 varme kilder, der sprudler fra jorden og tilbyder besøgende mulighed for at nyde alt fra traditionelle offentlige bade til private onsen på hoteller og ryokaner. Ud over at slappe af i de varme kilder kan besøgende udforske Beppus andre seværdigheder, herunder Beppu Jigoku (hells), hvor kokende mudder og farverige gejsere skaber en surrealistisk landskab, og det fredelige Umi Jigoku (havhule), hvor man kan nyde en afslappende fodbad.",
            title3: "Kinosaki Onsen:",
            info3: "Kinosaki Onsen er en malerisk onsen-by beliggende langs Japans vestlige kyst og kendt for sin traditionelle atmosfære og charmerende gade med syv offentlige badehus. Byen er omgivet af smuk natur, herunder bjerge, floder og skove, der skaber en idyllisk baggrund for en afslappende badeferie. Besøgende kan nyde en fredelig spadseretur langs Kinosaki Onsens gade, iført yukata og geta, og hoppe ind og ud af de syv offentlige badehus, der tilbyder forskellige varianter af varme kilder. Et besøg i Kinosaki Onsen er en unik mulighed for at opleve japansk kultur og tradition på sit mest autentiske.",
            imageUrl: onsen,
        },
        { 
            name: "Historiske steder",
            title: "Himeji Slot:",
            info: "Himeji Slot, også kendt som Hvide Herres Slot, er en perle af japansk middelalderlig arkitektur og en af landets mest storslåede slotte. Beliggende i Himeji by, Hyogo præfekturet, stiger dette slot majestætisk over byen med sine hvide mure og tårne, der skaber et imponerende syn. Bygget i det 17. århundrede og omhyggeligt restaureret til sin oprindelige pragt, er Himeji Slot en UNESCO-verdensarv og et symbol på Japans kulturarv. Besøgende kan udforske de velbevarede indre gårdhaver, tårne og forsvarsmure og få et indblik i livet i fortiden.",
            title2: "Nikko Toshogu-helligdommen:",
            info2: "Nikko Toshogu-helligdommen, beliggende i Nikko, Tochigi-præfekturet, er en helligdom dedikeret til Tokugawa Ieyasu, Japans stiftende shogun, og en af ​​landets mest pragtfulde og farverige helligdomme. Helligdommen, bygget i det 17. århundrede, er kendt for sin overdådige arkitektur, skulpturer og forgyldte dekorationer, der fremhæver Japans håndværksmæssige og åndelige dygtighed. Besøgende kan udforske de mange helligdomme og templer, der udgør komplekset, og beundre de intrikate detaljer og symboler, der pryder hvert hjørne.",
            title3: "Matsue Slot:",
            info3: "Matsue Slot, kendt som Pigenes Slot, er et sjældent eksempel på en autentisk japansk borg, der har overlevet århundreder med krig og forfald. Beliggende i Matsue by, Shimane præfekturet, er dette slot en imponerende struktur med sin massive tømmerkonstruktion og tårnhøje mure. Bygget i det 17. århundrede og omgivet af voldgrave og velplejede haver, er Matsue Slot et vindue til Japans samuraiæra og en vigtig del af landets historie. Besøgende kan udforske slotstårnene, forsvarsværkerne og udsigten over byen og søen fra toppen af borgen.",
            imageUrl: history,
        },
        { 
            name: "Kulturelle oplevelser",
            title: "Te-ceremonier:",
            info: "Te-ceremonier er en ældgammel japansk tradition, der omfatter forberedelse, servering og nydelse af matcha-te på en ceremoniel måde. Disse ceremonier er ikke kun en måde at drikke te på, men også en vigtig del af japansk kultur og æstetik. Under en te-ceremoni kan du lære om ritualernes betydning, betragte den kunstfærdige tilberedning af te og opleve øjeblikkets skønhed og ro.",
            title2: "Kimono-udlejning og påklædning:",
            info2: "Kimonoer er traditionelle japanske dragter, der bæres ved særlige lejligheder eller ceremonier. At leje og iføre sig en kimono er en fantastisk måde at opleve japansk kultur på første hånd. Du kan vælge mellem et bredt udvalg af farver og mønstre og blive klædt på af professionelle kimono-påklædere. Når du først har din kimono på, kan du tage en tur gennem byens gader og føle dig som en ægte japaner for en dag.",
            title3: "Samurai-oplevelser:",
            info3: "Samurai-oplevelser giver dig mulighed for at træde ind i fortiden og lære om den ærefulde samuraikultur. Disse oplevelser kan omfatte våbenøvelser, traditionelle kampkunst og endda en demonstration af samuraikunstnerens dygtighed med sværdet. Du kan også lære om samuraiens historie, kodeks og filosofi og få et dybere indblik i deres rolle i Japans historie og kultur.",
            title4: "Kalligrafiundervisning:",
            info4: "Kalligrafi er en ældgammel kunstform, hvor skønheden i skrift og penselstreg kombineres. At deltage i kalligrafiundervisning giver dig mulighed for at lære de grundlæggende teknikker og principper bag denne kunstform. Du vil arbejde med traditionelle pensler, blæk og papir og øve dig på at skrive japanske tegn med elegance og præcision. Gennem denne praksis kan du fordybe dig i skønheden ved kalligrafi og udvikle din egen stil og æstetik.",
            title5: "Ikebana (blomsterarrangement) workshops:",
            info5: "Ikebana er kunsten at arrangere blomster på en stilfuld og harmonisk måde. Disse workshops giver dig mulighed for at lære de grundlæggende principper for ikebana og skabe dine egne smukke arrangementer. Du vil arbejde med forskellige blomster, grene og beholdere og lære at skabe balance, proportioner og bevægelse i dine kompositioner. Ikebana-workshops er en berigende oplevelse, der forbinder dig med naturen og japansk æstetik på en dybere måde.",
            imageUrl: cultural,
        },
    ];

    const handleClick = (destination) => {
        setSelectedDestination(destination);
        // Scroll to the section with the info
        if (document.getElementById("destination-info")) {
            document.getElementById("destination-info").scrollIntoView({ behavior: "smooth" });
        } else {
            setTimeout(() => {
                document.getElementById("destination-info").scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
    };

    useEffect(() => {
        document.title = 'Ryoko | Oplevelser i Japan';
    }, []);

    return (
        <>
            <section>
                <div className="hero-banner mt-4 relative flex">
                    <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                    <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                        <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Oplevelser i Japan</h1>
                        <h2 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Opdag det fulde udvalg af fantastiske ting at se og lave i hele Japan</h2>
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-[1140px] my-4 mx-auto pt-2 pb-8 px-6 bg-[#00000073] rounded-lg">
                    <h3 className="text-4xl max-[425px]:text-2xl font-bold mb-4">Dyk ned i Japans Kultur: Oplevelser og Traditioner. Klik på billederne for at læse mere!</h3>
                    <ul className="flex flex-wrap gap-4 justify-center">
                        {interests.map((interests) => (
                            <li key={interests.name} className="interest-list">
                                <button onClick={() => handleClick(interests)} className="flex relative justify-center items-center overflow-hidden rounded-md">
                                    <img src={interests.imageUrl} alt={interests.name} className="max-w-[249px] !w-[249px] !h-[142px] brightness-75 object-cover" />
                                    <span className="m-2 text-white [text-shadow:_2px_4px_6px_black] text-2xl font-bold absolute">{interests.name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
            {selectedDestination && (
                <section id="destination-info">
                    <div className="max-w-[1140px] mb-4 mx-auto pt-2 pb-8 px-6 bg-[#00000073] rounded-lg">
                        <h3 className="text-4xl font-bold my-4 border-b-2 border-solid">{selectedDestination.name}</h3>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title}</h4>
                        <p className="mb-8 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info}</p>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title2}</h4>
                        <p className="mb-4 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info2}</p>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title3}</h4>
                        <p className="mb-4 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info3}</p>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title4}</h4>
                        <p className="mb-4 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info4}</p>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title5}</h4>
                        <p className="mb-4 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info5}</p>
                        <h4 className="text-3xl max-[425px]:text-2xl font-semibold mb-2">{selectedDestination.title6}</h4>
                        <p className="mb-4 text-xl text-white [text-shadow:_2px_4px_6px_black]">{selectedDestination.info6}</p>
                    </div>
                </section>
            )}
        </>    
    );
}
export default Oplevelser;