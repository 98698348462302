import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/relax/relax_hq.webp";
import content from "../../../images/japan_by_interests/relax/content.jpg";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Afslappelse i Japan';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Afslappelse i Japan</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Fra luksuriøse spaer med udsigt over byen til dukker i robuste havnære klippepøle og meditative haver til bjergretræter</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Sørg for at sætte tid af til lidt mig-tid under din rejse til Japan</h3>
                    <p>Du er aldrig langt fra boblende, geotermiske farvande i Japan, så sørg for at planlægge en tur til et af landets mange varme kilder resorts</p>
                    <p>At dyppe sig i de varme farvande er en dyk ned i den traditionelle japanske kultur og tilbyder afslapning for krop og sjæl. Tag op i bjergene i det centrale Japan eller ned sydpå til øen Kyushu og slap af i stenbade med udsigt over havet eller i luksuriøse bjergtilbagetog.</p>
                    <p>Du behøver ikke at tage for langt væk fra byen for at finde afslapning. Hoteller i Japans større byer er ofte udstyret med store spabade, der tilbyder afslapningsterapi med udsigt over bylandskabet. Hvis du har et lidt strammere budget, så tag til en af de mange udsøgte haver - såsom Shinjuku Gyoen National Garden i hjertet af Tokyo eller Kenroku-en i Kanazawa - for at slentre rundt på de grundlag, der er bygget til netop det formål at slappe af.</p>
                    <h3 className="font-bold mt-4">Zen Retreats: Oplev Indre Ro i Japan</h3>
                    <p>I Japan kan rejsende finde en oase af indre ro og afslapning på tværs af øens smukke landskaber. Uanset om du søger traditionelle japanske bade i bjergene eller luksuriøse spabehandlinger midt i byen, byder Japan på en overflod af muligheder for at forynge krop og sind. Læs videre for at opdage de bedste steder at finde zen og genopladning i Japan.</p>
                    <h3 className="font-bold mt-4">Naturlig Harmoni: Afslapning i Japans Prægtige Nationalparker</h3>
                    <p>Træd ind i Japans uspolerede natur og lad dig fortabe i skønheden i landets majestætiske nationalparker. Fra at bade i varme kilder under åben himmel med udsigt over havet til at vandre gennem tætte skove og bjergstier, tilbyder Japans nationalparker en unik mulighed for at fordybe sig i naturens helende kraft. Find din vej til indre harmoni blandt de grønne skove og klare farvande i Japans naturperler.</p>
                    <h3 className="font-bold mt-4">Urban Oaser: Afslapning i Japans Pulserende Byer</h3>
                    <p>Trods travlheden og det pulserende tempo i Japans storbyer er der stadig mulighed for at finde øjeblikke af fred og afslapning. Fra overdådige hotelspaer med udsigt over skyline til fortryllende haver skabt til refleksion og ro, byder Japans bymiljøer på en overraskende dybde af afslapningsmuligheder. Udforsk de bedste byoaser, hvor du kan trække vejret dybt og lade byens travlhed glide forbi.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;