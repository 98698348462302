import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/art/art_hq.webp";
import content from "../../../images/japan_by_interests/art/content.jpg";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japans Kunst og Design';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japans Kunst og Design</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Japans museer præsenterer alle former for kunst, indkapslet i bygninger, der viser traditionel og moderne arkitektur</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Japans kunst-, design- og arkitekturscener tilbyder alt fra det gamle til det avantgarde</h3>
                    <p>Besøg det 21. århundredes Museum for Samtidskunst, Kanazawa i Kanazawa for at se alt, der er moderne, eller tag til The National Museum of Art Osaka i Osaka eller Tokyo National Museum for noget lidt ældre. For en dybere forståelse af Edo-perioden (1603-1867) og popkunstneren Katsushika Hokusai og hans ukiyo-e-træsnit, besøg Hokusai Museum i Nagano-præfekturet.</p>
                    <p>At vandre gennem Japans store byers gader svarer til at browse i et designmuseum, da mange af bygningerne tilbyder arkitektonisk inspiration. Fra den kæmpestore Tokyo Station til Mikimoto Ginza 2 og Prada-bygningerne i de fornemme Tokyo-distrikter, kan du opdage strukturelle overraskelser ved hvert eneste hjørne. Alternativt kan du tage til et af landets egentlige designmuseer såsom Tokyo's 21_21 DESIGN SIGHT eller Archi-Depot Museum for at finde ud af mere om scenen.</p>
                    <h3 className="font-bold mt-4">Opdag Japans Kunstneriske Skatte: En Rejse gennem Landets Kunst og Design</h3>
                    <p>Tag på en fordybende rejse gennem Japans rige kunst- og designverden, hvor fortidens ædle håndværk møder nutidens innovative kreationer. Udforsk ikoniske kunstmuseer som det 21. århundredes Museum for Samtidskunst i Kanazawa og The National Museum of Art i Osaka for at se alt fra avantgarde til klassisk japansk kunst. Dyk ned i arkitekturens fascinerende verden, når du vandrer gennem Tokyos gader og oplever alt fra traditionelle templer til moderne skyskrabere. Lad dig fortrylle af Katsushika Hokusais ukiyo-e træsnit på Hokusai Museum i Nagano, og lad dig inspirere af Japans innovative designscener på steder som 21_21 DESIGN SIGHT i Tokyo. Velkommen til et rejseeventyr fyldt med æstetisk glæde og kulturel opdagelse i Japan.</p>
                    <h3 className="font-bold mt-4">Arkitektoniske Vidundere og Kunstneriske Perler: Oplev Japans Kunst og Design</h3>
                    <p>Gør dig klar til at blive forbløffet over Japans arkitektoniske mesterværker og kunstneriske skatte. Tag på en rejse gennem landet, hvor du kan beundre alt fra traditionelle shinto-helligdomme til futuristiske bygningsværker. Udforsk det ikoniske Tokyo Station og oplev dets imponerende arkitektoniske detaljer, eller slentre gennem Tokyos trendy kvarterer og beundre de stilfulde bygninger som Mikimoto Ginza 2 og Prada-bygningerne. Gå tilbage i tiden med et besøg på Hokusai Museum og dyk ned i Edo-periodens kunstneriske arv. Uanset om du er en arkitektur-entusiast eller en kunstelsker, vil Japan fortrylle dig med sin unikke blanding af tradition og innovation.</p>
                    <h3 className="font-bold mt-4">Design Paradiset: En Guide til Japans Kunst og Design Scene</h3>
                    <p>Træd ind i et sandt designparadis, når du udforsker Japans dynamiske kunst- og designverden. Tag på en rejse gennem landet, hvor hvert gadehjørne er fyldt med inspirerende arkitektur og kreativt flair. Besøg avantgarde designmuseer som Tokyo's 21_21 DESIGN SIGHT og oplev det nyeste inden for japansk design og innovation. Gå tilbage i tiden med et besøg på The National Museum of Art i Osaka og opdag Japans rige kunstneriske arv. Uanset om du er på udkig efter unikke modebutikker, trendy caféer eller kunstgallerier i verdensklasse, vil Japans kunst- og designscene ikke skuffe dig. Lad dig inspirere af landets kreative ånd og skab dine egne uforglemmelige oplevelser i Japan.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;