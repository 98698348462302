import React, { useState, useRef, useEffect } from 'react';

const AccessibilityBar = () => {
  const [markedText, setMarkedText] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const textRef = useRef(null);
  const messageRef = useRef(null);

  useEffect(() => {
    // Check if its the first visit
    const visited = localStorage.getItem('visited');
    if (!visited) {
      localStorage.setItem('visited', true);
      setShowMessage(true);
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (messageRef.current) {
      const message = messageRef.current;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // Calculate the position of the message box
      const messageHeight = message.offsetHeight;
      const messageWidth = message.offsetWidth;
      const bottomOffset = 20;
      const rightOffset = 20;
      const top = windowHeight - messageHeight - bottomOffset + window.scrollY;
      const left = windowWidth - messageWidth - rightOffset + 12;

      // Set the postion of the message box
      message.style.top = `${top}px`;
      message.style.left = `${left}px`;
    }
  };

  const markText = () => {
    if (textRef.current) {
      const selectedText = window.getSelection().toString();
      setMarkedText(selectedText);
      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(selectedText);

        // Fetch Danish voices
        const voices = window.speechSynthesis.getVoices();
        const danishVoice = voices.find(voice => voice.lang === 'da-DK');

        // Set Danish voice if available
        if (danishVoice) {
          utterance.voice = danishVoice;
        }
        utterance.rate = 0.8;
        window.speechSynthesis.speak(utterance);
      } else {
        alert('Text-to-speech is not supported in this browser.');
      }
    }
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const handleShowMessage = () => {
    setShowMessage(true);
  };

  return (
    <div ref={messageRef} className='absolute z-50'>
      <div className='accessibility-bar bg-white w-[40px] flex flex-col items-center justify-center rounded-bl-xl rounded-tl-xl border border-solid border-black'>
        <button onClick={markText} className='w-full flex justify-center rounded-tl-xl py-1 hover:bg-slate-300' title='Marker en tekst og få den læst op' aria-label='Marker en tekst og få den læst op'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" height={25} width={25}>
            <path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/>
          </svg>
        </button>
        <button onClick={handleShowMessage} className='w-full flex justify-center rounded-bl-xl py-1 hover:bg-slate-300' title='Tryk for at få hjælp til brugertilgængelig værktøjer' aria-label='Tryk for at få hjælp til brugertilgængelig værktøjer'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={25} width={25}>
            <path d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
          </svg>
        </button>
      </div>
      <div className='tts-box hidden'>
        <textarea ref={textRef} rows="5" cols="50" readOnly value={markedText} name='tts-mind' />
      </div>
      {showMessage && (
        <div className='absolute bg-white p-3 border border-solid border-black right-12 bottom-0 text-black font-semibold w-64 flex flex-col gap-4 rounded max-[425px]:bottom-20 max-[425px]:right-0'>
          <p>Brug vores værktøjslinje til at markere tekst og få den læst højt. Bare marker og klik på lydikonet for at lytte. God fornøjelse!</p>
          <button onClick={handleCloseMessage} className='border border-solid border-black rounded bg-slate-300 hover:bg-slate-400 transition-colors duration-300'>Luk boksen</button>
        </div>
      )}
    </div>
  );
};

export default AccessibilityBar;