import React, { useState, useEffect } from "react";
import Cookie from '../images/cookie.png';

const CookieBanner = () => {
    const [acceptedCookies, setAcceptedCookies] = useState(() => {
        return localStorage.getItem('cookiesAccepted') === 'true';
    });

    const acceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setAcceptedCookies(true);
    };

    useEffect(() => {
        // If cookies have already been accepted dont show the banner
        if (acceptedCookies) {
            return;
        }

        // Update banner position
        const updateBannerPosition = () => {
            const banner = document.querySelector(".cookie-banner");
            if (banner) {
                const scrollY = window.scrollY;
                const viewportHeight = window.innerHeight;
                const bannerHeight = banner.offsetHeight;
                const bottomSpace = 20;

                // Calculate top position to keep banner at bottom
                const topPosition = scrollY + viewportHeight - bannerHeight - bottomSpace;
                banner.style.top = `${topPosition}px`;
            }
        };

        // Call the function on initial render and scroll
        updateBannerPosition();
        window.addEventListener("scroll", updateBannerPosition);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("scroll", updateBannerPosition);
        };
    }, [acceptedCookies]);

    // Hide banner if it has already been accepted
    if (acceptedCookies) {
        return null;
    }

    return (
        <div className="cookie-banner absolute left-0 p-5 text-center z-[51] bg-white text-black font-semibold flex gap-2 max-w-sm border border-solid border-black rounded-r items-center">
            <img className="h-12" src={Cookie} alt="En chokolade cookie der er taget en bid af" title="The Cake Is A Lie!" />
            <div className="flex flex-col gap-4">
                <p>Vi respekterer dit privatliv. Denne hjemmeside bruger ikke cookies.</p>
                <button className="border border-solid border-black rounded bg-slate-300 hover:bg-slate-400 transition-colors duration-300 mx-4" onClick={acceptCookies}>Forstået!</button>
            </div>
        </div>
    );
};

export default CookieBanner;