import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/food/food_hq.webp";
import content from "../../../images/japan_by_interests/food/content.webp";

function Shopping() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Mad og Drikke i Japan';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Mad og Drikke i Japan</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Oplev Japans rige mad- og drikkekultur i dets izakaya, restauranter, ryokan og ryotei</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Japansk mad er en kunstform. Prøv favoritter som sushi og ramen, smag autentisk regional køkken og nyd lokal sake, shochu og øl</h3>
                    <p>Det japanske køkken er groft opdelt i washoku (traditionelle japanske retter som sushi, somen og tempura) og yoshoku (japanske versioner af vestlige retter som pasta, omelet og oksekødsgryde). Fra Hokkaido i nord til Kyushu og Okinawa i syd kan du finde meget sæsonbetonede regionale specialiteter og retter, der er lige så lækre som de er smukke, og som afspejler den lokale kultur. Sid ved en sushibar, spis på en traditionel ryotei, nyd vegetarisk shojin ryori i et kloster eller nyd afslappet mad på en izakaya eller yatai madbod. Supplér traditionelle og moderne tolkninger af det japanske køkken med sake fra et lokalt bryggeri i Niigata, nip til sød kartoffel shochu i Kagoshima, eller prøv en af Japans højt roste whiskyer fra Hokkaido, Osaka eller Nagano. Tag til Yamanashi for elegante vine, eller prøv de nyeste håndværksgin og mikrobryg i Tokyo. Udforsk, opdag, smag og nyd.</p>
                    <h3 className="font-bold mt-4">Smagsoplevelser i Japan: En Kulturel Rejse gennem Landets Kulinariske Skatte</h3>
                    <p>Oplev Japans mangfoldige gastronomi, der spænder fra traditionelle sushi og ramen til regionale delikatesser som Hokkaidos friske seafood og Kyushus fyldige tonkotsu suppe. Udforsk de lokale markeder, hvor du kan prøve autentiske ingredienser og smage på håndlavet sake og shochu. Fra street food til fine dining, Japan byder på en kulinarisk rejse fyldt med smag, traditioner og oplevelser.</p>
                    <h3 className="font-bold mt-4">Sake og Sushi: En Dybdegående Guide til Japans Traditionelle Drikke og Retter</h3>
                    <p>Dyk ned i Japans rige kultur gennem dens ikoniske sake og sushi. Lær om de forskellige typer sake og de bedste bryggerier i landet, mens du nipper til denne fortryllende drik. Udforsk sushiens kunstform, fra friskfanget fisk på Tokyo's Tsukiji-marked til håndrullet nigiri i Kyoto's traditionelle restauranter. Opdag historierne bag hvert stykke sushi og lær om sushiens betydning i japansk kultur.</p>
                    <h3 className="font-bold mt-4">Gastronomiske Eventyr i Japan: Fra Street Food til Michelin-stjerner</h3>
                    <p>Tag med på en rejse gennem Japans kulinariske landskab, hvor du kan smage på alt fra lokale favoritter på gadehjørner til udsøgte måltider på Michelin-stjerne restauranter. Udforsk forskellige regionale køkkener, fra det krydrede køkken i Osaka til det sødmefulde køkken på Okinawa. Dyk ned i de lokale traditioner, mens du nyder en skål dampende ramen eller prøver din hånd på at lave sushi med en erfaren itamae. Japan er fyldt med madoplevelser, der vil vække dine sanser og efterlade dig med uforglemmelige smagsoplevelser.</p> 
               </div>
            </div>
        </section>
    );
};

export default Shopping;