import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/heritage/heritage_hq.jpg";
import content from "../../../images/japan_by_interests/heritage/content.jpg";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japans Kulturarv';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Japans Kulturarv</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Vævet i Tiden: Opdagelse af Japans Kulturarv</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Opdagelse af Traditionelle Helligdomme og Templer</h3>
                    <p>Tag på en rejse gennem Japans rige historie og åndelige arv ved at besøge nogle af landets mest ikoniske helligdomme og templer. Oplev storslåede Shinto-helligdomme, som f.eks. det majestætiske Itsukushima-helligdom på Miyajima-øen, eller gå ind i den stille skønhed i buddhistiske templer som f.eks. Kinkaku-ji (Den Gyldne Pavillon) i Kyoto.</p>
                    <h3 className="font-bold mt-4">Eventyr i Historiske Borgbyer</h3>
                    <p>Dyk ned i Japans fortid ved at udforske de velbevarede borgbyer, der vidner om landets feudale historie. Gå på opdagelse i Himeji-borgen, en af de bedst bevarede borge i Japan, eller besøg det charmerende byområde i Kanazawa med dens imponerende Kenrokuen-have og det storslåede Kanazawa-slot.</p>
                    <h3 className="font-bold mt-4">Udforskning af Traditionelle Kunsthåndværk</h3>
                    <p>Dyk ned i Japans rige kunsthåndværkstradition ved at besøge håndværkere og værksteder, der har bevaret gamle teknikker og æstetik. Få indblik i keramikkens verden i det historiske byområde i Seto, eller tag til Takayama og oplev de traditionelle håndværk som træskæring og vævning.</p>
                    <h3 className="font-bold mt-4">Rejse gennem Legendariske Geografiske Vidundere</h3>
                    <p>Oplev Japans naturlige skønhed og betagende landskaber, der har inspireret kunstnere og digtere gennem århundrederne. Udforsk de snoede stier og klare søer i de japanske alper i Kamikochi, eller tag på vandretur gennem de mystiske skove og vulkanske landskaber på øen Kyushu.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;