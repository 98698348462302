import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/parks/parks_hq.jpg";
import content from "../../../images/japan_by_interests/parks/content.webp";

function Shopping() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | National Parker i Japan';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">National Parker i Japan</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Oplev historierne – opdag de mennesker og steder, der udgør Japans nationalparker</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Forunderlige Vidundere: Oplev Japans Naturlige Skatte i Nationalparkerne</h3>
                    <p>Fra de majestætiske bjerge til de smaragdgrønne skove tilbyder Japans nationalparker en uovertruffen oplevelse af naturens skønhed og mangfoldighed. Uanset om du er til vandreture langs krystalklare floder, udforske dybere ind i tætte skovområder eller blot nyde synet af sjældne dyrearter i deres naturlige habitat, er Japans nationalparker det ultimative rejsemål for natur- og eventyrelskere.</p>
                    <h3 className="font-bold mt-4">Fra Zen Have til Uendelige Skove: En Dybdegående Rejse gennem Japans Nationalparker</h3>
                    <p>Dyk ned i Japans rige naturlige arv med vores omfattende rejseguide til nationalparkerne. Udforsk de maleriske zen-haver, hvor roen og harmonien er i fokus, eller tag på en fortryllende vandretur gennem tætte skove, hvor hvert trin åbner op for nye opdagelser og eventyr. Uanset hvad du leder efter, vil Japans nationalparker berige din sjæl og forbløffe dine sanser.</p>
                    <h3 className="font-bold mt-4">Den Ultimative Guide til Naturoplevelser: Opdag Japans Nationalparker</h3>
                    <p>Fra sneklædte tinder til frodige dale og turkisblå søer tilbyder Japans nationalparker en bred vifte af naturoplevelser for rejsende i alle aldre og interesser. Vores guide vil lede dig gennem de bedste steder at besøge, aktiviteter at nyde og vigtige oplysninger om bevaring og bæredygtighed. Uanset om du er en eventyrlysten vandringsentusiast eller en afslappet naturelsker, vil Japans nationalparker efterlade dig fortryllet.</p>
                    <h3 className="font-bold mt-4">En Verden af Kontraster: Oplev Japans Naturlige Skønhed i Nationalparkerne</h3>
                    <p>Træd ind i en verden af kontraster, hvor moderne byliv møder uberørt natur, og gamle traditioner smelter sammen med moderne bekvemmeligheder. Vores guide vil tage dig med på en rejse gennem Japans nationalparker, hvor du kan udforske storslåede vandfald, betagende udsigtspunkter og dyreliv i deres naturlige habitat. Oplev skønheden og mangfoldigheden i Japans landskab, og lad dig fortabe i øjeblikkets magi.</p> 
               </div>
            </div>
        </section>
    );
};

export default Shopping;