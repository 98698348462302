import React, { useEffect } from "react";
import banner from "../../../images/japan_by_interests/culture/culture_hq.webp";
import content from "../../../images/japan_by_interests/culture/content.webp";

function GuideJan() {
    // Page title
    useEffect(() => {
        document.title = 'Ryoko | Japans Kultur';
    }, []);
    return (
        <section>
            <div className="hero-banner mt-4 relative flex">
                <img className="absolute h-full w-full object-cover brightness-[0.8] drop-shadow-[2px_4px_6px_black] border border-solid border-black" src={banner} alt="Japanese Cherry Blossom" />
                <div className="relative h-full w-full flex flex-col justify-center px-16 gap-2 min-[426px]:min-h-[500px] max-[425px]:px-2 max-[425px]:justify-start max-[425px]:py-2">
                    <h1 className="font-extrabold text-4xl text-white drop-shadow-[2px_4px_6px_black] max-[425px]:text-2xl">Guide</h1>
                    <h2 className="font-extrabold text-2xl text-white drop-shadow-[2px_4px_6px_black] max-w-4xl">Japan i Januar</h2>
                </div>
            </div>
            <div className="mt-4 py-4 px-40 text-xl min-[1240px]:max-[1500px]:px-20 min-[501px]:max-[800px]:p-4 max-[500px]:p-0">
                <div className="bg-[#00000073] p-4 rounded-lg">
                    <img className="max-[1240px]:max-w-full max-[1240px]:mx-0 max-[1240px]:mb-4 max-w-[600px] object-cover float-right drop-shadow-[2px_4px_6px_black] rounded-lg mx-4" src={content} alt="Two Geisha sitting on the floor" />
                    <h3 className="font-bold mt-4">Oplev den japanske kultur overalt: fra inden for de gamle helligdomme og templer i landet; på de trendy gader i Harajuku; på en venlig izakaya; eller ved en livlig festival</h3>
                    <p>De fleste inkluderer Kyoto og Nara i deres rejseplaner for Japan for at få en stor dosis traditionel japansk kultur. Selvom disse er to historiske og kulturelt rige destinationer, kan Japan prale af et sundt udvalg af mindre udforskede alternativer, der også er værd at overveje.</p>
                    <p>"Hvis du kan lide at være hands-on med din kultur, så book et ophold på en tempel for at få en smagsprøve på tempellivet, gå en tur på en af landets gamle stier, vær kreativ med et traditionelt håndværk i et lokalt værksted. Lige så mindeværdige oplevelser inkluderer traditionel japansk teater, avantgarde kunstinstallationer og lokale festivaler, der spænder fra det højtidelige til det støjende.</p>
                    <h3 className="font-bold mt-4">Dyk ned i Japans kulturelle skattekiste</h3>
                    <p>Tag på en fortryllende rejse gennem Japan og oplev en dybdegående indsigt i landets kulturelle rigdomme. Udforsk gamle templer og helligdomme, deltage i traditionelle teaceremonier, og dyk ned i historien bag samuraierne. Fra Kyoto's traditionelle geisha-distrikter til Tokyo's moderne kunstscene, Japan tilbyder en enestående kulturoplevelse, der vil forbløffe og fortrylle enhver rejsende.</p>
                    <h3 className="font-bold mt-4">Oplev Japans levende kultur</h3>
                    <p>Træd ind i en verden af levende traditioner og moderne innovationer i Japan. Tag en tur til landsbyer bevaret som tidskapsler, hvor du kan deltage i gamle håndværk og smage autentisk japansk køkken. Oplev den pulserende energi i byernes gader, hvor traditionelle festivaler blander sig med moderne popkultur. Uanset om du deltager i en sumo-turnering eller udforsker arkitekturen i gamle slotte, vil Japan forundre dig med sin kulturelle rigdom.</p>
                    <h3 className="font-bold mt-4">Fordyb dig i Japans kulturelle mangfoldighed</h3>
                    <p>Gå på opdagelse i en verden af kontraster i Japan, hvor gamle traditioner møder futuristiske trends. Udforsk zen-haver og shinto-helligdomme, og tag på en rejse gennem Japans rige kunsthistorie på museer og gallerier. Oplev det pulserende natteliv i storbyerne og deltag i lokale festivaler, hvor du kan danse med løver eller se fyrværkeri lyse himlen op. Uanset om du søger indre ro eller eventyr, vil Japan opfylde dine kulturelle ønsker med sin unikke blanding af gammelt og nyt.</p>
                </div>
            </div>
        </section>
    );
};

export default GuideJan;